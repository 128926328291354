import React, {Component} from 'react';
import './Swipelistvp2.css';
//import console = require('console');
class SwipeItem extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        yearsOfSmoking: "",
        left: 6,
        originalOffset: 0,
        velocity: 0,
        timeOfLastDragEvent: 0,
        touchStartX: 0,
        prevTouchX: 0,
        beingTouched: false,
        height: 0,
        intervalId: null,
        selected: 0
      };
    }
    
    componentDidMount() {
      const yearsOfSmoking = localStorage.getItem('yearsOfSmoking');
      this.setState({ yearsOfSmoking });
      window.setTimeout(() => this.setState({height: 65}), 50);
      document.getElementById("swipe-2").style.color ="green";
      document.getElementById("swipe-2").style.fontSize = "22px";
    }
    animateSlidingToZero() {
      let {left, velocity, beingTouched} = this.state;
      if (!beingTouched && left < -0.01) {
        velocity += 10 * 0.033;
        left += velocity;
        if (left < -350) {
          window.clearInterval(this.state.intervalId);
         // this.handleRemoveSelf();
        }
      //  this.setState({left, velocity});
      } else if (!beingTouched) {
      //  left = 0;
      //  velocity = 0;
        window.clearInterval(this.state.intervalId);
        this.setState({left, velocity, intervalId: null, originalOffset: 0});
      }
    }
    
    handleRemoveSelf() {
      this.setState({height: 0});
      window.setTimeout(() => this.props.onRemoval(), 250);
    }
    
    handleStart(clientX) {
      if (this.state.intervalId !== null) {
        window.clearInterval(this.state.intervalId);
      }
      this.setState({
        originalOffset: this.state.left,
        velocity: 0,
        timeOfLastDragEvent: Date.now(),
        touchStartX: clientX,
        beingTouched: true,
        intervalId: null
      });
    }
    
    handleMove(clientX) {
      if (this.state.beingTouched) {
        const touchX = clientX;
        const currTime = Date.now();
        const elapsed = currTime - this.state.timeOfLastDragEvent;
        const velocity = 20 * (touchX - this.state.prevTouchX) / elapsed;
        let deltaX = touchX - this.state.touchStartX + this.state.originalOffset;
        console.log(deltaX);

        if (deltaX < -1480) {
          //this.handleRemoveSelf();
          deltaX = -1480;
        } else if (deltaX > 140) {
          deltaX = 140;
        }
        var mySpan = document.getElementById("swipe-0");
        var spanWidth = mySpan.getBoundingClientRect().width;
        var cellWidth = spanWidth;

        var currentItem = ((-1*deltaX)/cellWidth) + 3;
        console.log(currentItem);
        this.setState({
          left: deltaX,
          velocity,
          timeOfLastDragEvent: currTime,
          prevTouchX: touchX,
          selected: Math.round(currentItem)
        });
      }
    }
    
    handleEnd() {
      const { selected } = this.state;
      localStorage.setItem('yearsOfSmoking', selected);
      console.log("selected: " + this.state.selected);
     /* this.setState({
        velocity: this.state.velocity,
        touchStartX: 0,
        beingTouched: false,
        intervalId: window.setInterval(this.animateSlidingToZero.bind(this), 33)
      });*/
      var mySpan = document.getElementById("swipe-2");
      var spanWidth = mySpan.getBoundingClientRect().width;
      var cellWidth = spanWidth;
      var leftOffset =  -(this.state.selected - 3) * cellWidth;
      /*
      if(window.screen.width > 325){
        if(this.state.selected > 11){
          leftOffset =  -(this.state.selected - 3) * (cellWidth-1);
        }
        
        if(this.state.selected == 20){
          leftOffset+=2;
        }
        
      }else{
        cellWidth = 54;
        leftOffset =  -(this.state.selected - 3) * cellWidth;
        if(this.state.selected > 11){
          leftOffset =  -(this.state.selected - 3) * (cellWidth-1) - 2;
        }
        if(this.state.selected > 17 ){
          leftOffset+=2;
        }
      }*/
      this.setState({
        left:leftOffset + 1,
        velocity: this.state.velocity,
        timeOfLastDragEvent: this.state.timeOfLastDragEvent,
        prevTouchX: this.state.prevTouchX
      });
      for(var i = 0; i < 20; i++){
        document.getElementById("swipe-"+ i).style.color = "black";
        document.getElementById("swipe-"+ i).style.fontSize = "16px";

       }
       if(this.state.selected < 21){
        var currentItem = this.state.selected - 1;
        document.getElementById("swipe-"+ currentItem).style.color = "green";
        document.getElementById("swipe-"+ currentItem).style.fontSize = "22px";
       }
    }
    
    handleTouchStart(touchStartEvent) {
      // touchStartEvent.preventDefault();
      // this.handleMotionStart(touchStartEvent.targetTouches[0].clientX);
      this.handleStart(touchStartEvent.targetTouches[0].clientX);
    }
    
    handleTouchMove(touchMoveEvent) {
      this.handleMove(touchMoveEvent.targetTouches[0].clientX);
    }
    
    handleTouchEnd() {
      this.handleEnd();
    }
    
    handleMouseDown(mouseDownEvent) {
      mouseDownEvent.preventDefault();
      this.handleStart(mouseDownEvent.clientX);
    }
    
    handleMouseMove(mouseMoveEvent) {
      this.handleMove(mouseMoveEvent.clientX);
    }
    
    handleMouseUp() {
      this.handleEnd();
    }
    
    handleMouseLeave() {
      this.handleMouseUp();
    }
    
    render() {
      return (
        <tr
          className="swipeItem"
          style={{height: this.state.height + 'px', transition: 'height 250ms ease-in-out'}}
          onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
          onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => this.handleTouchEnd()}
          // The following event handlers are for mouse compatibility:
          onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
          onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
          onMouseUp={() => this.handleMouseUp()}
          onMouseLeave={() => this.handleMouseLeave()}
        >
          <div
            className="swipeItem-content"
            style={{left: this.state.left + 'px'}}
          >
            {this.props.children}
          </div>
        </tr>
      );
    }
  }
  
  class SwipeList extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        counter: 1,
        items: {
          [0]: '01',
          [1]: '02',
          [2]: '03',
          [3]: '04',
          [4]: '05',
          [5]: '06',
          [6]: '07',
          [7]: '08',
          [8]: '09',
          [9]: '10',
          [10]: '11',
          [11]: '12',
          [12]: '13',
          [13]: '14',
          [14]: '15',
          [15]: '16',
          [16]: '17',
          [17]: '18',
          [18]: '19',
          [19]: '20'

        }
      };
    }
    
    addImage() {
      this.setState({
        counter: this.state.counter + 1,
        items: {...this.state.items, [this.state.counter]: 'http://lorempixel.com/350/65/'}
      });
    }
    
    removeItem(keyOfItemToRemove) {
      let nextItems = {};
      Object.keys(this.state.items).forEach(itemKey => {
        if (itemKey !== keyOfItemToRemove) {
          nextItems[itemKey] = this.state.items[itemKey];
        }
      });
      
      this.setState({items: nextItems});
    }
    
    render() {
      return (
        <div>
        <div className="Swipeline">&nbsp;</div>
          <table className="swipeList">
              <SwipeItem key={`swipeItem-1`} onRemoval={() => this.removeItem(1)}>
                {Object.keys(this.state.items).map((itemKey,i) =>
                  /* <img src={this.state.items[itemKey]} /> */
                  
                  <td id={"swipe-"+i} className="swipelistdata" data-key={itemKey}>
                    
                    <span>{this.state.items[itemKey]}</span>
                    
                  </td>
                )}
              </SwipeItem>
        </table>
        </div>
      );
    }
  }
  
        

export default SwipeList;
import React, {Component} from 'react'
import { Link, useNavigate} from 'react-router-dom';
import './style.css';
import { Icon } from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft'
import IconTickGreen from '../assets/img/icons/icon_tickgreen.svg';
import moment from "moment";
import {getRedeemedPerk, getToken, removeRedeemedPerk} from "../lib/localStorage";
import Modal from './Modal';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { StyleSheet, Text} from 'react-native';

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class PerkReceipt extends Component{

    constructor(props){
        super(props);
        this.state = {
            url: '',
            RedeemProductTitle: '',
            PointsChange: '',
            emailAddress: 'dummy@email.com',
            isLoading: false,
            code: 0,
            loading: false
        }
        this._handlePickup = this._handlePickup.bind(this);
        this._handleDontWant = this._handleDontWant.bind(this);
        this.startloading = this.startloading.bind(this);
    }
    startloading(){
        this.setState({
           loading: true
        });
      }
    componentWillMount () {
        const perk = getRedeemedPerk();
        const token = getToken();
        if (perk === null){
            this.props.navigate('/MyRewards');
        }
        const {url, RedeemProductTitle, PointsChange} = perk;
        this.setState({
            url,
            RedeemProductTitle,
            PointsChange,
            token
        });
    }

    _handlePickup = () => {
        // console.log(this.state.code);
        if (
            this.state.code === null
            || this.state.code === undefined 
            || this.state.code === ""
            || this.state.code.length < 4
        ) {
            alert("Please enter the 4 digit store code");
            return;
        }
        if (
            this.state.code === null
            || this.state.code === undefined 
            || this.state.code == " "
            || this.state.code.length < 0
        ) {
            alert("Field should not be empty");
            return;
        }
        this.setState({loading: true, isShowing: false});
        fetch(this.state.url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + getToken()
                },
                body: JSON.stringify({
                    "Type": "1",
                    "Status": "1",
                    "Code": this.state.code
                })
            })
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                this.setState({loading: false});
                //TODO
                // handle invalid or expired token(403), load login screen if invalid
                // console.log(data);
                // console.log(statusCode);
                if(statusCode === 200){
                    // removeRedeemedPerk();
                    this.props.navigate('/UnusedPerkReceipt');
                } else {
                    alert(data.error);
                }
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error(error);
            });
    }

    _handleModal = () => {
        this.setState({ modalVisible: !this.state.modalVisible})
    }

    _handleDontWant = () => {
        this.setState({isLoading: true});
        fetch(this.state.url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            },
            body: JSON.stringify({
                "Type": "1",
                "Status": "2"
            })
        })
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(async ([statusCode, data]) => {
                this.setState({isLoading: false});
                //TODO
                // handle invalid or expired token(403), load login screen if invalid
                // console.log(data);
                // console.log(statusCode);
                if(statusCode === 200){
                    removeRedeemedPerk();
                    this.props.navigate('/MyRewards');
                } else {
                    alert(data.error);
                }
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error(error);
            });
    }
    openModalHandler = () => {
        this.setState({
            isShowing: true
        });
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });
    }

    render() {


        return (
                <div className="parkrecmain">
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                        <Link to="/MyRewards" style={{position: 'absolute'}}>
                            <span style={{width: 12, height: 12, color:'#fff'}}> <Icon size={'20%'} icon={iosArrowThinLeft} /></span>
                        </Link>
                    <div>
                        <div className="perk-receipt-top">
                            <h3>All set!</h3>
                            <Text adjustsFontSizeToFit numberOfLines={3} style={{color:'#fff'}}>
                                You have 1 unused perk. View in {"\n"}
                                <Link style={{color:'#fff', textDecoration: 'underline'}} to="/MyRewards">My Rewards</Link>.
                                You can pick the item when you visit a{"\n"}
                                Good Guy Vapes store.
                            </Text>
                        </div>
                    </div>

                    <div className="parkbody">
                        <img src={IconTickGreen} alt="Right icon" />
                        <div className="PerkReceiptBodymargin">
                        <h3>{this.state.RedeemProductTitle}</h3>

                        <div className="packdetailsbody">
                            <p className="parkitemtitle">Item</p>
                            <p className="perkvalue">{this.state.RedeemProductTitle}</p>
                            <p className="parkitemtitle">Valid until</p> <span className="valid">VALID</span>
                            <p className="perkvalue">{moment(new Date()).add(1, 'y').format("DD MMM YYYY")}</p>
                            <p className="parkitemtitle">Points</p>
                            <p className="perkvalue">{this.state.PointsChange} Points</p>

                        </div>
                        </div>
                    </div>
                    <div className="perkbutton">
                        <input type="button" value="Confirmed Pickup by Staff" className="reddetailsbtnconf" onClick={this.openModalHandler} />
                        <Link to="/MyRewards">
                            <input type="button" value="I don't want this anymore"
                                   className="reddetailsbtndont" onClick={this._handleDontWant} />
                        </Link>
                    </div>
                    <Modal
                        className="modal"
                        show={this.state.isShowing}
                        close={this.closeModalHandler}>
                        <div className="Storecodetxt">Store code</div>
                        <input type="text" className="storeinput" onChange={ event => this.setState({code: event.target.value}) } required/>
                        <button className="storebtn" onClick={this._handlePickup}>Submit</button>

                    </Modal>
            </div>

        );
    }
}

const PerkReceiptWrapper = () => {
    const navigate = useNavigate();
    return <PerkReceipt navigate={navigate}/>;
};

export default PerkReceiptWrapper;

import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft'
import IconTickBlack from '../assets/img/icons/icon_tickblack.svg';
import './style.css';
import {getRedeemedPerk} from "../lib/localStorage";
import moment from "moment";
//'../assets/img/bg_pattern_grey.png'

class UnusedPerkReceipt extends Component{

    constructor(props){
        super(props);
        this.state = {
            url: '',
            RedeemProductTitle: '',
            PointsChange: '',
            ActivityTime: '',
            emailAddress: 'dummy@email.com'
        };
    }

    componentWillMount () {
        const perk = getRedeemedPerk();
        if (perk === null){
            this.props.navigate('/MyRewards');
        }
        const {url, RedeemProductTitle, PointsChange, ActivityTime} = perk;
        this.setState({
            url,
            RedeemProductTitle,
            PointsChange,
            ActivityTime
        });
    }

    render() {
                
        return (
                <div className="unusedperk">
                    <Link to="/MyRewards" className="welcreate">
                            <span className="backiconleft" style={{width: 12, height: 12, color:'#000'}}> <Icon size={'16%'} icon={iosArrowThinLeft} /></span>
                    </Link>
                <br/><br/>
                    <div className="parkbody">
                    <img src={IconTickBlack} alt="Right icon" />
                    <h3>{this.state.RedeemProductTitle}</h3>
                    
                    <div className="packdetailsbody">
                        <p className="parkitemtitle">Item</p>
                        <p className="perkvalue">{this.state.RedeemProductTitle}</p>
                        <p className="parkitemtitle">Redeemed on</p> <span className="redeemedtext">REDEEMED</span>
                        <p className="perkvalue">{moment(this.state.ActivityTime).format("DD MMM YYYY")}</p>
                        <p className="parkitemtitle">Points</p>
                        <p className="perkvalue">{this.state.PointsChange} Points</p>
                        
                    </div>
                </div>
                <div className="perkbutton">
                        <Link to="/MyRewards">
                            <input type="button" value="Go to My Rewards" className="unusedredoday" />
                        </Link>
                </div>
            </div>
        );
    }
}


export default UnusedPerkReceipt;
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'validate.js';
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import {getToken, getUserInfo, setOriginalUser, setOriginalToken} from "../lib/localStorage";
import Constants from "../lib/constants";
import {setToken, setUserInfo} from "../lib/localStorage";
// import {fetchUserInfo} from "../lib/api";

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}



const SearchUsersPage_int = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [logs, setLogs] = useState([]);

    
    // loading on mount
    const showHistory = async () => {
        setUsers([]);
        const fetchData = async () => {
            fetch(
                Constants.API_IMPERSONATION_LOGS,
                {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + getToken()
                }
            }
            ).then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            }).then(async ([statusCode, data]) => {
                if (statusCode === 200) {
                    // sort data by timestamp
                    data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    setLogs(data);
                }else{
                    console.log("Failed to load impersonation logs");
                }
            }
                ).catch((error) => {
                    console.error(error);
                });
            };
        fetchData();
    };

    const handleSearch = async () => {
        // verify searchTerm is not isEmpty
        if (isEmpty(searchTerm)) {
            alert("Please enter a search term.");
            return;
        }
        setLogs([]);// clear logs to show users

        // trim searchTerm and escape it
        const cleanTerm =  escape(searchTerm.trim());
        const url = Constants.API_SEARCH_USER + `${cleanTerm}`;
        fetch( url, {
            method: "GET",
            "Content-Type": "application/json"
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            //TODO
            // handle invalid or expired token(403), load login screen if invalid
            // console.log(data);
            // console.log(statusCode);
            if(statusCode === 200){
                setUsers(data);
            } else {
                alert(data.error);
            }
        })
        .catch((error) => {

            console.error(error);
        });
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };
    const _clear = () => {
        setSearchTerm('');
        setSelectedUser(null);
        setUsers([]);
        setLogs([]);
    };

    const handleImpersonate = () => {
        if (selectedUser) {
            // Code to impersonate user
            const user_id = selectedUser.id || selectedUser.target_user;
            
            const current_user = getUserInfo();
            const current_token = getToken();

            fetch(Constants.API_IMPERSONATE, {
                method: "POST",
                body: JSON.stringify({
                    "user_id": user_id
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + current_token
                },
            }).then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            }).then(async ([statusCode, data]) => {
                if (statusCode === 200) {
                    // console.log("Impersonation successful");
                    let response = await fetch(Constants.API_USERS + data.user_id +"/", {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                    setOriginalUser(current_user);
                    setOriginalToken(current_token);

                    setToken(data.token);
                    setUserInfo(data);
                    navigate("/");
                    window.location.reload(true);
                    //navigate to home page
                }else if(statusCode === 403){
                    alert("You are not authorized to impersonate this user."); 
                }
                else {
                    alert("Failed to login as the customer.");
                    console.log("Impersonation failed");
                }
            }).catch((error) => {
                console.error(error);
            });

        } else {
            alert("Please select a customer to impersonate.");
        }
    };

    return (
        // add inline style style={{font-family:Arial, padding: 20px}} to the div below
        <div>

            <div className="profilehead">
                        <Link to="/" className="welcreate">
                            <span className="backiconleft" style={{width: 12, height: 12, color:'#000'}}> <Icon size={'12%'} icon={iosArrowThinLeft} /></span>
                        </Link>
                        <span className="proaccountinfo">Search for customer</span>
            </div>
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Enter name, email, phone, or 4-digit customer code"
            />
            
            <div className="button-container">
                <button className='searchbutton' onClick={_clear}>Clear</button>
                <button className='searchbutton' onClick={handleSearch}>Search</button>
                <button className='searchbutton' onClick={showHistory}>History</button>
            </div>

            {users.length > 0 && (
                <ul>
                    {users.map(user => (
                        <li 
                            key={user.id} 
                            onClick={() => handleUserSelect(user)}
                            className={selectedUser && user.id === selectedUser.id ? 'selectedUser' : ''}
                        >
                            {user.first_name} {user.last_name} - {user.email} <br/> {user.PhoneNumber} - {user.CustomerCode}
                        </li>
                    ))}
                </ul>
            ) }

            {logs.length > 0 && (
        <div>
            {/* <h2>Your Impersonation History</h2> */}
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Username</th>
                        <th>IP</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map(log => (
                        <tr 
                        onClick={() => handleUserSelect(log)}
                        className={selectedUser && log.target_user === selectedUser.target_user ? 'selectedUser' : ''} 
                        key={log.timestamp}>
                            <td>{formatDate(log.timestamp)}</td>
                            <td>{log.target_user_name}</td>
                            <td>{log.ip_address}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            )}
            <div className='button-container'>
                <button className={selectedUser ? "searchbutton" : ''} onClick={handleImpersonate}>Login as Selected User</button>
            </div>
        </div>
    );
};

const SearchUsersPage = () => {
    const navigate = useNavigate();
    return <SearchUsersPage_int navigate={navigate}/>;
};

export default SearchUsersPage;

import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import './style.css';
import vapimg3 from '../assets/img/vapinglife3.png';

class VapingLife3_int extends Component{

    constructor(props) {
        super(props);
        this.state = { cigarettesInPack: 4 };
        this.onChange = this.onChange.bind(this);
        this._handleContinue = this._handleContinue.bind(this);
    }

    onChange(event) {
        this.setState({
            cigarettesInPack: event.nativeEvent.target.selectedIndex
        });
    }

    _handleContinue = () => {
        localStorage.setItem('cigarettesInPack', this.state.cigarettesInPack);
        this.props.navigate("/VapingLife4");
    }

    renderDropdown() {
        var number = [];
        for (let i = 0; i <= 20; i++) {
            number.push(<option key={i} value={i}>{i}</option>)
        }
        return number;

    }

    render() {
        return (
            <div>
                <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                        icon={iosArrowThinLeft}/></span>
                </Link>
                <div className="vap3div">
                    <img src={vapimg3} alt="vap3"/>
                    <p>
                        How many cigarettes<br/>
                        in your pack?
                    </p>
                </div>
                <div className="swipelisthide">&nbsp;</div>
                <div className="dropdownsingle">
                            <select className="select-number" onChange={this.onChange} value={this.state.cigarettesInPack} id="MySelect">
                                {this.renderDropdown()}
                            </select>
                        </div>
               <div>
                <div>
                    <div className="vapwelbtndiv">
                        <button className="vapbtncontinue" onClick={this._handleContinue}>Continue</button>
                        <Link to="/VapingLife2">
                        <button className="vapbtnback">Back</button>
                        </Link>
                    </div>
                </div>
                </div>

            </div>
        );
    }
}

const VapingLife3 = () => {
    const navigate = useNavigate();
    return <VapingLife3_int navigate={navigate} />
}

export default VapingLife3

import React, {Component} from "react";
import Switch from "react-switch";
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import './style.css';
import AuthService from '../lib/auth-service';


class Settings_int extends Component {
    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
    }

    doLogout() {
        AuthService.doLogout();
    }

    render() {
        return (
            <div>
                <Link to="/" className="welcreate">
                    <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                     icon={iosArrowThinLeft}/></span>
                </Link>
                {/*<div className="settingelement">
                    <label>
                        <span>Allow notifications </span>
                        <span className="toggle">
                        <Switch
                            onChange={this.handleChange}
                            checked={this.state.checked}
                            id="normal-switch"
                        />
                        </span>
                    </label>
                </div>*/}
                <div className="settingelement" onClick={() => this.props.navigate('/Profile')}>
                    <span>Change Account Information</span>
                </div>
                <div className="settingelement" onClick={() => window.location.href = 'mailto:rewards@goodguyvapes.com'}>
                    <span>Report An Issue With Your Rewards</span>
                </div>
                <div className="settingelement" onClick={() => window.location.href = 'mailto:info@goodguyvapes.com,contact@zeru-ai.com'}>
                    <span>Report a Bug on the App</span>
                </div>
                <div className="settingelement" onClick={this.doLogout}>
                    <span>Logout</span>
                </div>
            </div>
        );
    }
}



const Settings = () => {
    const navigate = useNavigate();
    return <Settings_int navigate={navigate}/>;
};

export default Settings;

import Constants from "./constants";
const localStorage = window.localStorage;

export default class AuthService {

    static isLoggedIn() {
        const token = localStorage.getItem(Constants.KEY_TOKEN);
        if( token === null) {
            return false;
        }
        return !!(token && token !== '');
    }

    static doLogout() {
        localStorage.removeItem(Constants.KEY_TOKEN);
        localStorage.removeItem(Constants.KEY_USER_INFO);
        localStorage.removeItem(Constants.KEY_REDEEMED_PERK);
        window.location.reload(true);
    }

};

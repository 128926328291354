import React, {Component} from 'react'
import Constants from "../lib/constants";
import Slider from "react-slick";
import './style.css';
import MenusWrapper from '../screens/Menus';
import {Link} from 'react-router-dom';
import {getToken} from "../lib/localStorage";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';


const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;
class BlogTabView extends Component{
    constructor(props) {
        super(props);
        this.state = {
            carouselData: [],
            listData: [],
            loading: false
        };
        this.startloading = this.startloading.bind(this);
    }
    startloading(){
        this.setState({
            loading: true
        });
    }
    async componentWillMount() {

        fetch(Constants.API_BLOGS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            }
        })
        .then(response => {
            this.setState({loading: true});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([statusCode, data]) => {
            this.setState({loading: false});
            if (data.length) {
                let carouselData = data.slice(0, 3);
                let listData = data.slice(3);
                this.setState({
                    carouselData,
                    listData
                });
            }
            if(statusCode === 200){
                this.setState({ads: data});
            }
            console.log(data)
        });
    }
    reformatResponse = (data) => {
        return data.map( item => {
            return item;
        });
    }
    renderItemsNews() {
        const {listData} = this.state;
        let itemsNews = [];
        if (listData.length) {
            for (const value of listData) {
                itemsNews.push(
                    <div key={value.id}>
                        <div className="blogpageNews">
                            <div className="blogbody">
                                <Link to={"/BlogDetails/" + value.id}>
                                    <div className="blogimage">
                                    <div className="blogNewsthumb" style={{backgroundImage: `url(${value.image})`}}></div>
                                    </div>
                                    <h2>{value.title}</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return itemsNews;
    }
    renderItems() {
        const {carouselData} = this.state;
        let items = [];
        if (carouselData.length) {
            for (const value of carouselData) {
                items.push(
                    <div key={value.id}>
                        <div className="blogpage">
                            <div className="blogbody">
                                <Link to={"/BlogDetails/" + value.id}>
                                    <div className="blogimage">
                                    <div className="blogthumbtabview" style={{backgroundImage: `url(${value.image})`}}></div>
                                    </div>
                                    <h2>{value.title}</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return items;
    }
    render() {
        const settings = {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
        return (
            <div>
                <div className="blogbgcolor">
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
                <div className="bloghead">
                    <span id="menuwhite"><MenusWrapper /></span>
                    <h2 className="Redeemableheader">Blog</h2>
                </div>
                    <Slider {...settings}>
                    { this.renderItems() }
                    </Slider>

                </div>
                <div className="blogMorenews">
                    <p className="blognewstitle">More news</p>
                    {this.renderItemsNews()}
                </div>
            </div>
        );
    }
}


export default BlogTabView;

import React from 'react';
import * as ReactDOMClient from 'react-dom/client'; // Changed import
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './lib/alert';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container); // Create a root.

root.render(<App />); // Initial render

serviceWorker.unregister();

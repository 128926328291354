import React, {Component} from 'react'
import {Link} from "react-router-dom";
import Carouselexp from '../screens/Carousel';
import MenusWrapper from '../screens/Menus';
import Constants from "../lib/constants";
import IconReward from '../assets/img/icons/icon_gift.svg';
import IconStore from '../assets/img/icons/icon_store.svg';
import IconGroup from '../assets/img/icons/icon_group.svg';
import {fetchUserInfo} from "../lib/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {isImpersonating, getOriginalToken, getOriginalUser, setToken, 
    setUserInfo, removeOriginalToken, removeOriginalUser} from "../lib/localStorage";

class Home extends Component{

    constructor(props) {
        super(props);
        this.state = {
            entries: [{}, {}, {}],
            slider1ActiveSlide: 0,
            userInfo: {},
            ads: [],
            isImpersonating: isImpersonating()
        };
  
    }
  
    async componentWillMount() {
        this.fetchAds();
        fetchUserInfo().then(userInfo => {
            this.setState({userInfo});
        });
    }

    fetchAds() {
        fetch(Constants.API_ADS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            
            if(statusCode === 200){
                this.setState({ads: data});
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    exitImpersonation = () => {
        setToken(getOriginalToken());
        setUserInfo(getOriginalUser());
        removeOriginalToken();
        removeOriginalUser();
        window.location.reload();
    }

    render() {
        const {ads, userInfo} = this.state;
        return (
            <div className="homebody">
            {this.state.isImpersonating && (
                <div className="impersonation-header">
                    <span className="impersonation-text">You are impersonating as a customer.</span>
                    <button className="exit-impersonation-button" onClick={this.exitImpersonation}>Exit</button>
                </div>
                )
            }
                   
                <MenusWrapper />

                <div id="homebg">
                    <Link to="/Notifications">
                        <FontAwesomeIcon icon={faBell} className="notification-icon"/>
                    </Link>

                    <div className="hometextdiv">
                        <h3 className="hometext">Welcome back, {userInfo.first_name}</h3>
                        {userInfo.is_staff && (
                        <h3 className="hometext">You are using the GGV Staff view.</h3>
                        )}
                        {!userInfo.is_staff && (
                        <h3 className="hometext">You have <span>{userInfo.Points}</span> Points</h3>
                        )}
                    </div>
                    <div style={{width:'100%',height:'155px'}}>
                        <Carouselexp ads={ads}/>

                    </div>
                    {/* Hide this block if the user is not staff */}
                    {userInfo.is_staff && (
                        <div className="Rewards">
                            <div className="homelinkborder">
                                <Link to="/StoreManager">
                                    <img src={IconReward} alt="icon"/><span>Store Manager</span>
                                {/* TODO: change the icon */}
                            </Link>
                        </div>
                    </div>
                    )}
                    {!userInfo.is_staff && (
                    <div className="Rewards Rewardsmargin">
                        <div className="homelinkborder">
                            <Link to="/MyRewards">
                                <img src={IconReward} alt="icon"/><span>View My Rewards</span>
                            </Link>
                        </div>
                    </div>
                    )}
                    <div className="Rewards">
                        <div className="homelinkborder">
                            <a href="https://goodguyvapes.com/apps/store-locator">
                                <img src={IconStore} alt="icon"/><span>Store Locator</span>
                            </a>
                        </div>
                    </div>
                    <div className="Rewards">
                        <a href="https://www.facebook.com/groups/thegoodguysociety">
                            <img src={IconGroup} alt="icon"/><span>Join the Good Guy Society</span>
                        </a>
                    </div>
                </div>
            </div>

        );
    }
}



export default Home;
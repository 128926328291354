import React, {Component} from 'react'
import './style.css';
import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
//import noImage from '../assets/img/notfound.png';
import {getToken, setBlogDetails} from "../lib/localStorage";
import Constants from "../lib/constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;
class BlogDetails extends Component{

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            blogDetails: [],
            loading: false
        };
        this.blogpostdetails = this.blogpostdetails.bind(this);
        this.startloading = this.startloading.bind(this);
    }
    startloading(){
        this.setState({
            loading: true
        });
    }
    async componentWillMount() {
        const {id} = this.props.match.params;
        console.log(id)
        fetch(Constants.API_BLOGS + id + "/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            }
            
        })
        .then(response => {
            this.setState({loading: true});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            console.log(data)
            if (status === 200) {
                this.setState({loading: false});
                this.setState({
                    blogDetails: data
                })
                
            }
        });
    }
    blogpostdetails = () => {
        this.setState({loading: true});
        fetch(Constants.API_BLOGS, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            },
            body: JSON.stringify({
                "RedeemProductId": this.state.blogDetails.id.toString(),
                "LoyaltyTierId": "",
                "Status": "0",
                "Type": "1"
            })
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            
            //TODO
            // handle invalid or expired token(403)
            if(statusCode === 201){
                setBlogDetails(data);
                this.props.history.push("/Blog");
            } else {
		        this.setState({loading: false});
                alert(data.error);
            } 
        })
        .catch((error) => {
            console.error(error);
        });
    }
    render() {
        const {blogDetails} = this.state;
        return (
            <div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
                <div className="blogdetailsimg">
                        <div className="blogdetailsback">
                            <Link to="/Blog" className="blogdetails-back-icon">
                            <span className="backiconleft" style={{width: 12, height: 12, color: '#fff'}}> <Icon size={'12%'}
                                                                                        icon={iosArrowThinLeft}/></span>
                            </Link>
                            <Link to="/">
                                <FontAwesomeIcon icon={faStar} className="blogdetails-home-icon"/>
                            </Link>
                        </div>
                </div>
                <div className="blgodetailsbody">

                    <div className="blogthumb" style={{backgroundImage: `url(${blogDetails.image})`}}></div>
                    <div className="blogdetailstext">
                        <h2>{blogDetails.title}</h2>
                        <p>{blogDetails.content}</p>
                    </div> 
                </div>
            </div>
        );
    }
}


export default BlogDetails;

import React, {Component} from 'react'
import Menus from '../screens/Menus';
import { Link } from 'react-router-dom';
import './style.css';
import Constants from "../lib/constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import {fetchUserInfo} from "../lib/api";
const noImg = require('../assets/img/noProductImage.jpg');

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class PerkCategories extends Component{

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            perks: [],
            loading: false
        };
        this.startloading = this.startloading.bind(this);
    }
    startloading(){
        this.setState({
           loading: true
        });
      }
    async componentWillMount() {
        fetchUserInfo().then(userInfo => {
            this.setState({userInfo});
        });
        fetch(Constants.API_PRODUCT_CATEGORIES, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            this.setState({loading: false});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200) {
                this.setState({perks: data.results});
            }
        });
    }

    renderItems() {
        const {perks} = this.state;
        let items = [];
        const {userInfo} = this.state;
        var count =0;
        let valid_ones = [];
        if (perks.length) {

            for (var i =0;i<perks.length;i++){
                if ( !perks[i].NotAvailableInStates.toUpperCase().includes(userInfo.InternalState)){
                    valid_ones.push(i);
                }
            } 
            var last_even_idx = valid_ones.length %2 ==0 ? valid_ones.length/2 :(valid_ones.length-1)/2;

            for (var j =0;j<last_even_idx;j++) {
                const value1 =perks[valid_ones[2*j]];
                const value2 =perks[valid_ones[2*j+1]];
                        const img1 = value1.Image ? value1.Image : noImg;
                        const img2 = value2.Image ? value2.Image : noImg;
                        items.push(
                            <div class="PerkCategoryGrid">
                            <div className="perkcategoryblock" key={value1.id}>
                                <Link to={"/PerksInCategory/" + value1.id+"/"+value1.Name}>
                                    <img src={img1} alt={value1.Name} />
                                </Link>
                            </div>
                            <div className="perkcategoryblock" key={value2.id}>
                                <Link to={"/PerksInCategory/" + value2.id+"/"+value2.Name}>
                                    <img src={img2} alt={value2.Name} />
                                </Link>
                            </div>
                        </div>
                    )
            }
            if (valid_ones.length %2 !=0){
                const value1 =perks[valid_ones[valid_ones.length-1]];
                        const img1 = value1.Image ? value1.Image : noImg;
                        items.push(
                            <div class="PerkCategoryGrid">
                            <div className="perkcategoryblock" key={value1.id}>
                                <Link to={"/PerksInCategory/" + value1.id+"/"+value1.Name}>
                                    <img src={img1} alt={value1.Name} />
                                </Link>
                            </div>
                            </div>
                        )
            }
        }

        return items;
    }

    render() {

        const {userInfo} = this.state;
        console.log("state aaa: "+userInfo.InternalState);
        if (userInfo.InternalState === "XX")
        {
            alert('You cannot redeem perks yet. Please bring this to a Good Guy Vapes store.');
            return (
            <div>
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                <div className="redeemeableblock">
                    <span id="menuwhite"><Menus /></span>
                    <h2 className="Redeemableheader">Perk Categories</h2>
                </div>
            </div>    
                );
        }
        else
        {        
            return (
                    <div>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                            />
                        <div className="redeemeableblock">
                            <span id="menuwhite"><Menus /></span>
                            <h2 className="Redeemableheader">Perk Categories</h2>
                        </div>
                            { this.renderItems() }
                        </div>
               
                );
        }
    }
}


export default PerkCategories;
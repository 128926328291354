import React, {Component} from 'react'
import { ImageBackground, Text, View } from 'react-native';
//import {Button} from "native-base";
import { Link } from 'react-router-dom';
import icon from '../assets/img/welcome/icon_thumb.png';

class Welcome extends Component{

    render() {
        return (
            <div className="welbackground">
                <View>
                </View>
                {/*<View style={{flex: 1, alignItems: 'center'}}>
                    <Image source={require('../assets/img/welcome/icon_thumb.png') }/>
                </View>*/}
                <View style={{flex:2,justifyContent: "center", alignItems: "center"}}>
                    <img src={icon} alt="like" className="welimg"/>
                    <h2 className="welcomeh2">
                        Welcome to Good Guy Vapes
                    </h2>

                    <Text className="welcomebody">
                        When you sign up, you will earn<br/>
                        exclusive rewards and perks for<br/>
                        shopping with us!</Text>
                    <Text className="welcomejointext">Sign up below to join us!</Text>
                </View>
                    <div className="welbuttonarea">
                    <View>
                        <Link to="/SignUp" className="welcreate">Create Account</Link>
                        <View>
                            <div className="wellogin">
                                <span>Already have an account? </span>
                                <Link to="/Login"> Sign in</Link>
                            </div>
                        
                        </View>
                    </View>
                    </div>
                </div>
        );
    }
}


export default Welcome;
import Constants from "../lib/constants";
const localStorage = window.localStorage;

export default class Storage {

    store = async (key, value) => {
        try {
            await localStorage.setItem(key, value);
        } catch (err) {
            throw err;
        }
    }
    
    find = async (key) => {
        try {
            const value = await localStorage.getItem(key);
            return value;
        } catch (err) {
            throw err;
        }
    }

    remove = async (key) => {
        try {
            await localStorage.removeItem(key);
        } catch (err) {
            throw err;
        }
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem(Constants.KEY_USER_INFO))
    }
}
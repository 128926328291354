import React, {Component} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import "./style.css";
import logo from '../assets/img/goodguy.png';
import Constants from "../lib/constants";
//import DatePicker from "react-datepicker";
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
//import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-mobile-datepicker';

function main() {
  function convertDate(date, formate) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return formate
       .replace(/Y+/, year)
       .replace(/M+/, month)
       .replace(/D+/, day)
       .replace(/h+/, hour)
       .replace(/m+/, minute)
       .replace(/s+/, second);
}
}
const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

class SignUpForm_int extends Component {
    constructor(props) {
        super(props);
        this.state = {
          type: 'password',
          score: 'null',
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          confirmPassword: '',
          Zipcode: null,
          BirthDate: null,
          loading: false,
          time: new Date(),
		    isOpen: false,
        }

        this.showHide = this.showHide.bind(this);
        this.passwordStrength = this.passwordStrength.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.signupUser = this.signupUser.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
      }
      handleClick = () => {
        this.setState({ isOpen: true });
      }

      handleCancel = () => {
        this.setState({ isOpen: false });
      }

      handleSelect = (time) => {
        this.setState({BirthDate: moment(time).format("YYYY-MM-DD"),  isOpen: false});
      }
      
      showHide(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        })
    }

    passwordStrength(e) {
        if (e.target.value === '') {
            this.setState({
                score: 'null'
            })
        }
    }

    handleDateChange(date) {
        this.setState({BirthDate: moment(date).format("YYYY-MM-DD")});
    }

    handleChange(event) {

        //console.log(event.target.value);
        if (event.target.name === 'email') {
            this.setState({email: event.target.value});
        }

        if (event.target.name === 'password') {
            this.setState({password: event.target.value});
        }

        if (event.target.name === 'confirmPassword') {
            this.setState({confirmPassword: event.target.value});
        }

        if (event.target.name === 'first_name') {
            this.setState({first_name: event.target.value});
        }

        if (event.target.name === 'last_name') {
            this.setState({last_name: event.target.value});
        }

        if (event.target.name === 'BirthDate') {
            this.setState({BirthDate: event.target.value});
        }

        if (event.target.name === 'Zipcode') {
            this.setState({Zipcode: event.target.value});
        }

    }

    async signupUser(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch(Constants.API_USERS, {
            method: "POST",
            body: JSON.stringify({
                "email": this.state.email,
                "password": this.state.password,
                "confirmPassword": this.state.confirmPassword,
                "last_name": this.state.last_name,
                "first_name": this.state.first_name,
                "Zipcode": this.state.Zipcode,
                "BirthDate": this.state.BirthDate
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(async ([statusCode, data]) => {
                if (statusCode === 400) {
                    this.setState({loading: false});
                    if (data.email) {
                        alert('The email is already registered. Please login or use a different email address.');
                    } else {
                        alert('Sign up failed. Please check all fields & try again');
                    }
                } else if (statusCode === 201) {
                    this.props.navigate("/Welcome");
                }

            })
            .catch((error) => {
                console.log(error);

            });

    }

    render() {
        return (
            <div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
              />
            <div>
                <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color:'#000'}}> <Icon size={'12%'} icon={iosArrowThinLeft} /></span>
              </Link>
            </div>
              <form onSubmit={this.signupUser} className="col.md.5" id="form">
              <h3 className="signupheader">Tell us about yourself <img className="signuplogo" src={logo} alt="Logo" /></h3>
            <label>
              First Name <br></br>
              <input type="text" placeholder="First name" name="first_name" onChange={this.handleChange} className="inputfield"/>
            </label>
            <br/>
            <label>
              Last Name <br/>
              <input type="text" placeholder="Last name" name="last_name" onChange={this.handleChange} className="inputfield"/>
            </label>
            <br/>
            <label>
              Email <br/>
              <input type="email" placeholder="Email address" name="email" onChange={this.handleChange} className="inputfield"/>
            </label>
            <br/>
            <label>
              Set Password <br/>
              <input type={this.state.type} name="password" onChange={this.handleChange} className="inputfield inuppass"/>
              <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ? 
              <FontAwesomeIcon icon={faEye} /> 
              : <FontAwesomeIcon icon={faEyeSlash} />
              }</span>
              
            </label>
            <br/>
            <label>
              Confirm password <br/>
              <input type={this.state.type} name="confirmPassword" onChange={this.handleChange} className="inputfield inuppass" autocomplete="off"/>
              <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ? 
              <FontAwesomeIcon icon={faEye} /> 
              : <FontAwesomeIcon icon={faEyeSlash} />
              }</span>
            </label>
            <br/>
            <label>
              Date of Birth <br/>
              <button onClick={this.handleClick} className="datepic"
             >{this.state.BirthDate}</button>
				<DatePicker
					value={this.state.time}
					isOpen={this.state.isOpen}
                    min={new Date(1900, 0, 1)}
					onSelect={this.handleSelect}
                    onCancel={this.handleCancel}
                    confirmText	={"Set"}
                    cancelText={"Cancel"}
                />
            </label>
            <br/>
            <label>
              Zip Code <br/>
              <input type="number" placeholder="Zipcode" name="Zipcode" onChange={this.handleChange} className="inputfield"/>
            </label>
            <br/>
            <input type="checkbox" required/><span> I have read and agree to the terms and conditions and priavacy statement prior to making an account</span>
            <br/>
            <input type="submit" value="Submit" />
        </form>
      </div>
        );
    }
}

const SignUpForm = () => {
    const navigate = useNavigate();
    return <SignUpForm_int navigate={navigate}/>;
}

export default SignUpForm;
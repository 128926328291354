import React, {Component} from "react";
import './style.css';
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
//import Barcode from "react-native-barcode-builder";
import Profileimg from './profile.png';
import {fetchUserInfo} from "../lib/api";
import moment from 'moment';
import Constants from "../lib/constants";
import {getToken} from "../lib/localStorage";
import IconCamera from "../assets/img/icons/icon_camera.svg";

class Profile extends Component{

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            Zipcode: '',
            firstName: '',
            lastName: '',
            isLoading: true,
            email: 'dummy@email.com',
            userInfo: {},
            profileImage: null
        }
        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);
    }

    async componentWillMount() {
        fetchUserInfo().then(userInfo => {
            if (userInfo !== null) {
                // console.log({userInfo});
                this.setState({
                    Zipcode: userInfo.Zipcode,
                    firstName: userInfo.first_name,
                    lastName: userInfo.last_name,
                    userInfo: userInfo,
                    email: userInfo.email
                });

            }
            this.setState({userInfo});
        });
    }

    handleImageSelect(event) {
        this.setState({
            profileImage: URL.createObjectURL(event.target.files[0])
        });
        this._uploadNewProfileImage(event.target.files[0]);
    }

    _getFormData() {
        let data = {};
        if (this.state.password !== ''){
            data.password = this.state.password;
        }
        data.Zipcode = this.state.Zipcode;
        data.first_name = this.state.firstName;
        data.last_name = this.state.lastName;
        return data;
    }

    _uploadNewProfileImage = (file) => {

        this.setState({isLoading: true});
        let userId = this.state.userInfo.id;

        let profileUpdatingUri = Constants.API_USERS + userId + '/';
        let formData = new FormData();
        formData.append('ProfilePhoto', file);

        try{
            fetch(profileUpdatingUri, {
                method: "PATCH",
                headers: {
                    "Authorization": "Token " + getToken()
                },
                body: formData
            })
            .then(response => {
                this.setState({isLoading: false});
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then( async ([status, result ]) => {
                if (status === 200) {
                    this.setState({
                        userInfo: result
                    });
                    alert('Profile photo updated');
                } else {
                    alert('Failed to update profile photo');
                }
            } )
            .catch((error) => {
                alert('Failed to update profile photo');
            });
        } catch(err) {
            console.error(err);
            alert('Failed to update profile photo');
        }

        this.setState({isLoading: true});

    }

    async updateUserInfo() {
        let userId = this.state.userInfo.id;
        const payload = this._getFormData();
        // validation
        // TODO
        // move to a separate function
        if (this.state.Zipcode !== '') {
            let isnum = /^\d+$/.test(this.state.Zipcode);
            if (!isnum) {
                alert("Zip Code must be all digits");
                return false;
            }
            if (this.state.Zipcode.length !== 5) {
                alert("Zipcode must be 5 digits");
                return false;
            }
        }
        if (this.state.password !== '') {
            if (this.state.password.length < 6) {
                alert("Password should be at least 6 characters long");
                return false;
            }
        }

        // first name & last name is required
        if (this.state.firstName === '' || this.state.lastName === '') {
            alert("Both first name & last name is required");
            return false;
        }
        fetch(Constants.API_USERS + userId + '/', {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + getToken()
                },
                body: JSON.stringify(payload)
            })
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(async ([statusCode, data]) => {
                if(statusCode === 400){
                    alert("Couldn't save data");
                } else if(statusCode === 200){
                    alert("Information updated!");
                }
            })
            .catch((error) => {
                console.error(error);
                alert("Something went wrong");
            });
    }

    render() {
        let profilePhotoUri = this.state.userInfo.ProfilePhoto ? this.state.userInfo.ProfilePhoto : Profileimg;
        if(this.state.profileImage){
            profilePhotoUri = this.state.profileImage;
        }

        return (
                <div>
                    <div className="profilehead">
                        <Link to="/" className="welcreate">
                            <span className="backiconleft" style={{width: 12, height: 12, color:'#000'}}> <Icon size={'12%'} icon={iosArrowThinLeft} /></span>
                        </Link>
                        <span className="proaccountinfo">My Account Info</span>
                    </div>
                    <div className="editproimg">
                    <div className="profile-avatar" style={{backgroundImage: `url(${profilePhotoUri})`}}> 
                        <input type="file" accept="image/*" id="selectedFile" style={{display: "none"}} onChange={this.handleImageSelect}/>
                        <span className="IconCamera" onClick={() => document.getElementById('selectedFile').click()}><img src={IconCamera} alt="camera" className="cameraicon"/></span> 
                    </div>
                        
                    </div>
                    <div className="probackground">
                        <form>
                            <table id="table">
                                <tbody>
                                    <tr>
                                        <td><span className="sptablecl">First Name</span></td>
                                        <td> <input type="text" value={this.state.firstName} onChange={(event) => {this.setState({firstName: event.target.value})}}/></td>
                                    </tr>
                                    <tr>
                                        <td><span>Last Name</span></td>
                                        <td> <input type="text" value={this.state.lastName} onChange={(event) => {this.setState({lastName: event.target.value})}} /></td>
                                    </tr>
                                    <tr>
                                        <td><span>Email</span></td>
                                        <td> <span className="profilevalright">{this.state.userInfo.email}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Date of Birth</span></td>
                                        <td> <span className="profilevalright">{moment(this.state.userInfo.BirthDate).format('MM/DD/YYYY')}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Zip Code</span></td>
                                        <td> <input type="text" value={this.state.Zipcode} onChange={(event) => {this.setState({Zipcode: event.target.value})}}/></td>
                                    </tr>
                                    <tr>
                                        <td><span>Password</span></td>
                                        <td> <input type="text" placeholder="****" onChange={(event) => {this.setState({password: event.target.value})}}/></td>
                                    </tr>
                                </tbody>
                            </table>
                           
                        </form>

                    </div>
                    <div className="redeembtndiv">
                        <input className="Redetbtn" type="button" value="Save" onClick={this.updateUserInfo}/>
                    </div>




                </div>

                   //<ImageBackground style={{flex: 1}} source={require('../assets/img/ggmasked.png')} resizeMode={'contain'}>

        );
    }
}



export default Profile;
export default {

    // local storage keys
    KEY_TOKEN: 'token',
    KEY_ALLOW_NOTIFICATION: 'allowNotification',
    KEY_USER_INFO: 'userInfo',
    KEY_PERKS: 'perks',
    KEY_REDEEMED_PERK: 'REDEEMED_PERK',
    KEY_ORIGINAL_USER: 'originalUser',
    KEY_ORIGINAL_TOEKN: 'originalToken',

    //api
    API_BASE_URL: 'https://goodguyvapes.net/api',
    API_USERS: 'https://goodguyvapes.net/api/users/',
    API_LOGIN: 'https://goodguyvapes.net/api/api-token-auth/',
    API_REDEEMABLE_PRODUCTS: 'https://goodguyvapes.net/api/redeemableproducts/',
    API_ADS: 'https://goodguyvapes.net/api/ads/',
    API_REDEEM: 'https://goodguyvapes.net/api/redeemactivities/new/',
    URL_RECOVER_PASSWORD: 'https://goodguyvapes.net/password_reset/recover',
    API_RESEND_MAIL: 'https://goodguyvapes.net/api/send_validation_email/',
    API_LOALTYTIERS: 'https://goodguyvapes.net/api/loyaltytiers/',
    API_UPGRADETIER: 'https://goodguyvapes.net/api/redeemactivities/new/',
    API_NOTIFICATIONS: 'https://goodguyvapes.net/api/notifications/',
    API_ACTIVITIES: 'https://goodguyvapes.net/api/redeemactivities/',
    API_BLOGS: 'https://goodguyvapes.net/api/blogs/',
    API_PRODUCT_CATEGORIES: 'https://goodguyvapes.net/api/categories/',
    API_SEARCH_USER: 'https://goodguyvapes.net/api/users/search/',
    API_IMPERSONATE: 'https://goodguyvapes.net/impersonate/',
    API_IMPERSONATION_LOGS: 'https://goodguyvapes.net/api/impersonation_logs/',

    // redux actions
    ACTION_LOGIN: 'LOGIN',
    ACTION_LOGOUT: 'LOGOUT',
    ACTION_SIGNUP: 'SIGNUP',
    ACTION_REDEEM_PRODUCT: 'REDEEM_PRODUCT',
    ACTION_PERKS: 'REDEEM_PRODUCT',

    // social media url
    SOCIAL_FB: 'https://www.facebook.com/groups/thegoodguysociety',
    SOCIAL_IG: 'https://www.instagram.com/goodguyvapes/',
    SOCIAL_YT: 'https://www.youtube.com/channel/UCxP5vdnJSTFbOynle3qVPfg',
    SOCIAL_TW: 'https://twitter.com/GoodGuyVapes',

    // routes

}
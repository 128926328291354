import React, {Component} from 'react'
import './style.css';
import {Icon} from 'react-icons-kit'
import {Link} from 'react-router-dom';
import bgimg from '../assets/img/vapinglife.png';
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';

class VapingLife1 extends Component{

    render() {
        return (
            <div>
            <Link to="/Welcome" className="welcreate">
                    <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                    icon={iosArrowThinLeft}/></span>
            </Link>
                <div className="vaping1img">
                    <img src={bgimg} alt="bgimg"/>
                </div>

                <div>
                    <div>
                        <p className="vap1title">
                            Welcome to My Vaping Life!
                        </p>
                        <p className="vapwelcombody">
                            You took the hard decision to <br/>
                            stop smoking. Congratulations!
                        </p>
                    </div>
                    <div className="vapwelbtndiv">
                    <Link to="/VapingLife2">
                        <button className="vapwelcombtn">Let's start</button>
                    </Link>
                    </div>
                </div>
            </div>
        );
    }
}


export default VapingLife1;
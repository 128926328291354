import React, {Component} from 'react'
import './style.css';
import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import moment from 'moment';

class HealthDetails extends Component{

    constructor(props) {
        super(props);
        this.state = {
            bloodPressure: 0,
            remainingNicotine: 0,
            carbonMonoxid: 0,
            craving: 0,
            gumsAndTeeth: 0,
            carbonMonoxideAndOxygen: 0,
            heartAttack: 0,
            senseOfTaste: 0,
            respirator: 0,
            physicalLimitions: 0,
            lungs: 0,
            tar: 0,
            heartDisease: 0,
            lungCancer: 0,
            stroke: 0,
            cancer: 0,
            bloodflow: 0,
            cessation: 0,
        }

    }

    async componentDidMount() {
        const quitDate = localStorage.getItem("quitDate");

        const todayTime = moment().format();
        const ms = moment(todayTime,"YYYY-MM-DD HH:mm:ss").diff(moment(quitDate,"YYYY-MM-DD HH:mm"));
        const duration = moment.duration(ms);
        const hourGap = Math.abs(duration.hours()) + Math.abs(duration.days()) * 24 + Math.abs(duration.years()) * 365 * 24;
        const minuteGap = Math.abs(duration.minutes()) + hourGap * 60;
        const daysGap = Math.abs(duration.days()) * 24 + Math.abs(duration.years()) * 365 * 24;
        const weeksGap = Math.ceil(daysGap / 7);

        const bloodPressure = minuteGap >= 20 ? 100 : minuteGap * 5;

        const remainingNicotine =hourGap >= 48 ? 100 : hourGap * 2.08;

        const heartDisease = duration.years() >= 10 ? 100 : duration.years() * 10;

        const craving = daysGap >= 10 ? 100 : daysGap * 10;

        const gumsAndTeeth = daysGap >= 14 ? 100 : daysGap * 7.14;

        const carbonMonoxid = hourGap >= 12 ? 100 : hourGap * 8.3;

        const heartAttack = hourGap >= 24 ? 100 : hourGap * 4.17;

        const senseOfTaste = hourGap >= 48 ? 100 : hourGap * 2.08;

        const respirator = weeksGap >= 12 ? 100 : weeksGap * 8.3;

        const physicalLimitions = weeksGap >= 6 ? 100 : weeksGap * 16.66;

        const lungs = hourGap >= 72 ? 100 : hourGap * 1.38;

        const tar = weeksGap >= 30 ? 100 : weeksGap * 3.33;

        const lungCancer = duration.years() >= 20 ? 100 : duration.years() * 5;

        const stroke = duration.years() >= 5 ? 100 : duration.years() * 20;

        const cancer = duration.years() >= 5 ? 100 : duration.years() * 20;

        const bloodflow = duration.years() >= 5 ? 100 : duration.years() * 20;

        const cessation = weeksGap >= 4 ? 100 : weeksGap * 25;

        this.setState({
            bloodPressure: +bloodPressure.toFixed(),
            remainingNicotine: +remainingNicotine.toFixed(),
            heartDisease: +heartDisease.toFixed(),
            craving: +craving.toFixed(),
            gumsAndTeeth: +gumsAndTeeth.toFixed(),
            carbonMonoxid: +carbonMonoxid.toFixed(),
            heartAttack: +heartAttack.toFixed(),
            senseOfTaste: +senseOfTaste.toFixed(),
            respirator: +respirator.toFixed(),
            physicalLimitions: +physicalLimitions.toFixed(),
            lungs: +lungs.toFixed(),
            tar: +tar.toFixed(),
            lungCancer: +lungCancer.toFixed(),
            stroke: +stroke.toFixed(),
            cancer: +cancer.toFixed(),
            bloodflow: +bloodflow.toFixed(),
            cessation: +cessation.toFixed()
        })
    }

    render() {
        const state = this.state;
        const percentage = 36;
        return (
            <div>
                <div className="healthhead">
                        <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                    icon={iosArrowThinLeft}/></span>
                    </Link>
                        <span className="heathtitile">Health</span>
                </div>
                <div>
                    <table className="hdtable">
                        <tr>
                            <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.bloodPressure} text={`${state.bloodPressure}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your blood pressure, pulse rate and the temperature of your hands and feet are beginning to return to normal</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.remainingNicotine} text={`${state.remainingNicotine}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Remaining nicotine in your bloodstream has fallen to less than half of normal peak daily level</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.carbonMonoxid} text={`${state.carbonMonoxid}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Carbon monoxide and oxygen levels back to normal</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.heartDisease} text={`${state.heartDisease}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Sudden death risk lowered</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.craving} text={`${state.craving}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your cravings will be substantially reduced to only 1 or 2 episodes a day</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={this.state.gumsAndTeeth} text={`${this.state.gumsAndTeeth}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your gums and teeth regain bloodflow similar to non-smokers</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.carbonMonoxid} text={`${state.carbonMonoxid}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Carbon monoxide and oxygen levels back to normal</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.heartAttack} text={`${state.heartAttack}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Half as likely to have a heart attack</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.senseOfTaste} text={`${state.senseOfTaste}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your sense of taste and smell drastically improves, and damaged nerve endings are healing</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.respirator} text={`${state.respirator}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                        </td>
                            <td className="healthtr2">Standardization of respiratory function</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.physicalLimitions} text={`${state.physicalLimitions}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Elimination of physical limitions from cigarettes. Most of your withdrawl symptoms should be over</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.lungs} text={`${state.lungs}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your lungs start to recover and it becomes easier to breathe</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.tar} text={`${state.tar}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">You will begin to cough up and remove tar and other lung obstructions</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.heartDisease} text={`${state.heartDisease}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Heart disease death risk reduced to half</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.lungCancer} text={`${state.lungCancer}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Risk of developing lung cancer at same level as a non-smoker</td>
                        </tr>
                        <tr>
                            <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.stroke} text={`${state.stroke}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Your risk of having a stroke and cervical cancer are the same as a non-smoker now</td>
                        </tr>
                        <tr>
                            <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.cancer} text={`${state.cancer}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">You're half as likely to get cancer of the mouth, throat, esophagus or bladder now</td>
                        </tr>
                        <tr>
                            <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.bloodflow} text={`${state.bloodflow}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Bloodflow begins to increase, lowering the risk of clots and a stroke</td>
                        </tr>
                        <tr>
                        <td className="healthtr1"><div style={{ width: "60px" }}>
                                <CircularProgressbar percentage={state.cessation} text={`${state.cessation}%`}
                                 styles={{background: {fill: "#3e98c7"},
                                 text: {fill: "#000", fontSize: '24px',},
                                 path: {stroke: "#4ED91B"},
                                 trail: { stroke: "#ccc" }
                                 }}/>
                                </div>
                            </td>
                            <td className="healthtr2">Cessation related anger, anxiety, difficulty concentrating, impatience, insomnia, restlessness and depression have ended</td>
                        </tr>
                    </table>

                </div>
                     <p className="helthdiscla">
                            Disclaimer: These statistics are based World Health Organization
                            [link: <a style={{color: '#00f'}} href='https://www.who.int/tobacco/quitting/benefits/en/' target="_blank">World Health Organization</a>]
                    </p>
            </div>
        );
    }
}


export default HealthDetails;

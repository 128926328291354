import React, {Component} from 'react';
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import Constants from "../lib/constants";
import {setToken, setUserInfo} from "../lib/localStorage";
import ClipLoader from 'react-spinners/ClipLoader';
import {css} from '@emotion/react';

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'password',
            score: 'null',
            username: '',
            password: '',
            loading: false
        };
        this.showHide = this.showHide.bind(this);
        this.startloading = this.startloading.bind(this);
        this.passwordStrength = this.passwordStrength.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loginUser = this.loginUser.bind(this);
    }

    showHide(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          type: this.state.type === 'input' ? 'password' : 'input'
        })  
      }
      startloading(){
        this.setState({
           loading: true
        });
      }
      passwordStrength(e){
        if(e.target.value === ''){
          this.setState({
            score: 'null'
          })
        }
      }

    handleChange(event) {
        // console.log(event.target.value);
        if (event.target.name === 'username'){
            this.setState({username: event.target.value});
        }

        if (event.target.name === 'password'){
            this.setState({password: event.target.value});
        }
    }

    async loginUser(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch( Constants.API_LOGIN, {
                method: "POST",
                body: JSON.stringify({
                        "username": this.state.username,
                        "password": this.state.password
                    }),
                headers: {
                    "Content-Type": "application/json"
                }
                
            })
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
                
            })
            .then(async ([statusCode, data]) => {
                
                if(statusCode === 400){
                    document.getElementById("Errlogin").innerHTML = "Your username or password is incorrect.";
                    this.setState({loading: false});
                    console.error(data);
                } else if(statusCode === 200){

                    let response = await fetch(Constants.API_USERS + data.user_id +"/", {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                    const info = await response.json();

                    if (!info.is_active) {
                        alert('Your account is not activated yet. Please verify your email & try again');
                        this.setState({loading: false});
                        return;
                    }
                    setToken(data.token);
                    setUserInfo(data);
                    window.location.reload(true);
                }
                
            })
            .catch((error) => {
                console.error(error);
            });

    }

    render() {
        return (
            <div>
                
                  <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                    <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                        icon={iosArrowThinLeft}/></span>
                    </Link>
                    <form onSubmit={this.loginUser} className="col.md.5" id="form">
                        <h3>Login</h3>
                        <h6 id="Errlogin" style={{color:'red',margin:'5px 0px 5px 0px'}}></h6>
                        <label>
                            Email <br/>
                            <input type="email" placeholder="Email address" name="username" className="inputfield" onChange={this.handleChange} />
                        </label>
                        <br/>
                        <label>
                            Password <br/>
                            <input type={this.state.type} onChange={this.handleChange} className="inputfield inuppass"
                                autoComplete="off" name="password"/>
                            <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ?
                                <FontAwesomeIcon icon={faEye}/>
                                :
                                <FontAwesomeIcon icon={faEyeSlash}/>
                                }
                            </span>
                        </label>
                        <br/>
                        <input type="checkbox"/><span> Remember me</span>
                        <a href="https://goodguyvapes.net/accounts/password_reset" style={{float: 'right',}}> Forgot
                            password?</a>
                        <br/>
                        <input type="submit" value="Let's Go"/>

                    </form>
                
            </div>
        );
    }
}


export default Login;
import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import './style.css';

class Carouselexp extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            index: 0,
            direction: null,
        };
    }

    handleSelect(selectedIndex, e) {
        if (e) {
            this.setState({
                index: selectedIndex,
                direction: e.direction,
            });
        } else {
            // Handle the case where `e` is undefined
            this.setState({
                index: selectedIndex,
            });
        }
    }

    renderCarousel() {
        const {ads} = this.props;

        let items = [];

        for (const value of ads) {
            items.push(
                <Carousel.Item key={value.id}>
                    <img
                        className="d-block w-100"
                        src={value.Image}
                        alt="First slide"
                    />
                </Carousel.Item>
            )
        }

        return items;
    }

    render() {
        const {index, direction} = this.state;

        return (
            <Carousel
                activeIndex={index}
                direction={direction}
                onSelect={this.handleSelect}>

                {this.renderCarousel()}

            </Carousel>
        );
    }
}

export default Carouselexp;
import React, {Component} from 'react'
import './style.css';
import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import bgimg from '../assets/img/vapinglife5.png';


class VapingLife5 extends Component{

    constructor(props) {
        super(props);
        this.state = {
            preDecimal: 0,
            postDecimal: 0
        };
        this.onChange = this.onChange.bind(this);
    }

    cogarettepacfrom() {
        var number = [];

        for (let i = 0; i <= 99; i++) {
            number.push(<option key={i} value="{i}">{i}</option>)
        }
        return number;
        
    }
    cogarettepacto() {
        var number = [];

        for (let i = 1; i <= 99; i++) {
            number.push(<option key={i} value="{i}">{i}</option>)
        }

        return number; 
    }

    onChange(event) {
        const key = event.nativeEvent.target.name;
        let value = event.nativeEvent.target.selectedIndex;
        if (key === 'postDecimal') {
            value = value+1;
        }
        this.setState({
            [key]: value
        }, () => {
            localStorage.setItem('priceOfCigarette', this.state.preDecimal+"."+ this.state.postDecimal);
        });
   }

    render() {
                
        return (
            <div>
                <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                        icon={iosArrowThinLeft}/></span>
                </Link>
                <div className="vap4div">
                    <div>
                        <img src={bgimg}/>
                        <p>
                            What's the price of <br/>
                            your cigarette pack?
                        </p>
                    </div>
                    <div className="cigarettepack">
                        <div className="selectdiv">
                            <select className="select-number" onChange={this.onChange} name="preDecimal">
                                {this.cogarettepacfrom()}
                            </select>
                        </div>
                        <div>
                            <p className="cigarettecomma" style={{justifyContent: 'center'}}>,</p>
                        </div> 
                        <div className="selectdiv">
                            <select className="select-number" onChange={this.onChange} name="postDecimal">
                                {this.cogarettepacto()}    
                            </select>
                        </div>
                    </div> 
                </div>
                <div className="vapwelbtndiv">
                    <Link to="/VapingLife6">
                    <button className="vapbtncontinue">Continue</button>
                    </Link>
                    <Link to="/VapingLife4">
                    <button className="vapbtnback">Back</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default VapingLife5

import React, {Component} from 'react'
import './style.css';
import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import bgimg from '../assets/img/vapinglife6.png';
import Storage from "../lib/storage";
import moment from 'moment';
import DatePicker from 'react-mobile-datepicker';


class VapingLife6 extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isDatePickerVisible: false,
            isTimePickerVisible: false,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm"),
            quitDate: moment(),
            isOpenTime: false,
            isOpenDate: false
        };
        this.Storage = new Storage();
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleDateCancel = this.handleDateCancel.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleTimeClick = this.handleTimeClick.bind(this);
        this.handleTimeCancel = this.handleTimeCancel.bind(this);
        this.handleTimeSelect = this.handleTimeSelect.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }
    handleDateChange(date) {
        this.setState({date: moment(date).format("YYYY-MM-DD")});
    }
    handleTimeChange(Time) {
        this.setState({Time: moment(Time).format("HH:mm")});
        
    }
    handleDateClick = () => {
        this.setState({ isOpenDate: true });
      }

      handleDateCancel = () => {
        this.setState({ isOpenDate: false });
      }

      handleDateSelect = (date) => {
        this.setState({date: moment(date).format("YYYY-MM-DD"),  isOpenDate: false});
        
      }
      handleTimeClick = () => {
        this.setState({ isOpenTime: true });
      }

      handleTimeCancel = () => {
        this.setState({ isOpenTime: false });
      }

      handleTimeSelect = (time) => {
        this.setState({time: moment(time).format("HH:mm"),  isOpenTime: false});

      }
    _showDateTimePicker = () => this.setState({ isDatePickerVisible: true });

    _hideDateTimePicker = () => this.setState({ isDatePickerVisible: false });

    _handleDatePicked = (date) => {
        this.setState({
            date: moment(date).format("MM/DD/YYYY"),
            quitDate: date
        });
        this._hideDateTimePicker();
    };

    _handleTimePicked = (date) => {
        this.setState({
            time: moment(date).format("HH:mm"),
            quitDate: date
        });
        this._hideTimePicker();
    };

    _showTimePicker = () => this.setState({ isTimePickerVisible: true });

    _hideTimePicker = () => this.setState({ isTimePickerVisible: false });


    _handleContinue = () => {
        this.Storage.store("quitDate", JSON.stringify(this.state.quitDate));
        this.props.navigation.navigate('VapingLife');
    }

    _handleBack = () => {
        this.props.navigation.navigate('VapingLife5');
    }

    render() {
        const timeConfig = {
            'hour': {
                format: 'hh',
                caption: 'Hour',
                step: 1,
            },
            'minute': {
                format: 'mm',
                caption: 'Min',
                step: 1,
            },
        }
        return (
            <div>
                <Link to="/Welcome" className="welcreate">
                    <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                   icon={iosArrowThinLeft}/></span>
                </Link>
                <div className="vap4div">
                    <img src={bgimg} alt="bgimg"/>
                    <p>
                        When did you quit <br/>
                        smoking?
                    </p>
                </div>
                <div className="vapdaydate">
                    <p className="vap6day">Day</p>
                    <button className="vap6date" onClick={this.handleDateClick}>{this.state.date}</button>
              </div>
              <div className="vaptime">
                    {console.log(this.state.time)}
                    {localStorage.setItem("quitDate", this.state.date +" "+ this.state.time)}
                    <p className="vap6time">Time</p>
                    <button className="vap6ctime" onClick={this.handleTimeClick}>{ this.state.time}</button>
              </div>
                <DatePicker
					value={new Date()}
					isOpen={this.state.isOpenDate}
					onSelect={this.handleDateSelect}
                    onCancel={this.handleDateCancel}
                    confirmText	={"Set"}
                    cancelText={"Cancel"}
                />
                <DatePicker
                    dateConfig={timeConfig}
					isOpen={this.state.isOpenTime}
					onSelect={this.handleTimeSelect}
                    onCancel={this.handleTimeCancel}
                    confirmText	={"Set"}
                    cancelText={"Cancel"}
                />
                <div className="vapwelbtndiv">
                    <Link to="/VapingLife">
                    <button className="vapbtncontinue">Continue</button>
                    </Link>
                    <Link to="/VapingLife5">
                    <button className="vapbtnback">Back</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default VapingLife6;

import React, {Component} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {HashRouter as Router, Routes, Route} from "react-router-dom";
import {PrivateRoute, RedirectLoggedIn} from "./components/auth-router";

import Settings from "./screens/Settings";
import Profile from "./screens/Profile";
import MyRewardsWrapper from "./screens/MyRewards";
import PerkDetailsWrapper from "./screens/PerkDetails";
import SignUpForm from "./screens/SignUpForm";
import Login from "./screens/Login";
import UpgradeTier from "./screens/UpgradeTier";
import UnusedPerkReceipt from "./screens/UnusedPerkReceipt";
import PerkReceiptWrapper from "./screens/PerkReceipt";
import RedeemablePerks from "./screens/RedeemablePerks";
import PerkCategories from "./screens/PerkCategories";
import PerksInCategory from "./screens/PerksInCategory";
import Welcome from "./screens/Welcome";
import Terms from "./screens/Terms";
import Home from "./screens/Home";
import BlogTabView from "./screens/BlogTabView";
import VapingLife1 from "./screens/VapingLife1";
import VapingLife2 from "./screens/VapingLife2";
import VapingLife3 from "./screens/VapingLife3";
import VapingLife4 from "./screens/VapingLife4";
import VapingLife5 from "./screens/VapingLife5";
import VapingLife6 from "./screens/VapingLife6";
import VapingLife from "./screens/VapingLife";
import HealthDetails from "./screens/HealthDetails";
import BlogDetails from "./screens/BlogDetails";
import Notifications from "./screens/Notifications";
import Activity from "./screens/Activity";
import SearchUsersPage from "./screens/StoreManager";


class App extends Component {

    render() {
        return (
            <div id="Appbody" className="col-md-5">
                <Router>
                    <Routes>

                        <Route path="/Login" element={<RedirectLoggedIn><Login /></RedirectLoggedIn>} />
                        <Route path="/SignUp" element={<RedirectLoggedIn><SignUpForm /></RedirectLoggedIn> } />
                        <Route path="/Welcome" element={ <RedirectLoggedIn><Welcome /></RedirectLoggedIn>} />
                        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                        <Route path="/Settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                        <Route path="/Profile" element={<PrivateRoute><Profile /></PrivateRoute> } />
                        <Route path="/MyRewards" element={<PrivateRoute><MyRewardsWrapper /></PrivateRoute> } />
                        <Route path="/PerkDetails/:id" element={<PrivateRoute><PerkDetailsWrapper /></PrivateRoute> } />
                        <Route path="/UpgradeTier" element={<PrivateRoute><UpgradeTier /></PrivateRoute> } />
                        <Route path="/PerkReceipt" element={<PrivateRoute><PerkReceiptWrapper /></PrivateRoute> } />
                        <Route path="/UnusedPerkReceipt" element={<PrivateRoute><UnusedPerkReceipt /></PrivateRoute> } />
                        <Route path="/RedeemablePerks" element={<PrivateRoute><RedeemablePerks /></PrivateRoute> } />
                        <Route path="/Terms" element={<PrivateRoute><Terms /></PrivateRoute> } />
                        <Route path="/Blog" element={<PrivateRoute><BlogTabView /></PrivateRoute> } />
                        <Route path="/VapingLife1" element={<PrivateRoute><VapingLife1 /></PrivateRoute> } />
                        <Route path="/VapingLife2" element={<PrivateRoute><VapingLife2 /></PrivateRoute> } />
                        <Route path="/VapingLife3" element={<PrivateRoute><VapingLife3 /></PrivateRoute> } />
                        <Route path="/VapingLife4" element={<PrivateRoute><VapingLife4 /></PrivateRoute> } />
                        <Route path="/VapingLife5" element={<PrivateRoute><VapingLife5 /></PrivateRoute> } />
                        <Route path="/VapingLife6" element={<PrivateRoute><VapingLife6 /></PrivateRoute> } />
                        <Route path="/VapingLife" element={<PrivateRoute><VapingLife /></PrivateRoute> } />
                        <Route path="/HealthDetails" element={<PrivateRoute><HealthDetails /></PrivateRoute> } />
                        {/* <Route path="/BlogDetails/:id" element={<PrivateRoute><BlogDetails /></PrivateRoute> } />  we will remove this page*/}
                        <Route path="/Notifications" element={<PrivateRoute><Notifications /></PrivateRoute> } />
                        <Route path="/Activity" element={<PrivateRoute><Activity /></PrivateRoute> } />
                        <Route path="/PerkCategories" element={<PrivateRoute><PerkCategories /></PrivateRoute> } />
                        <Route path="/PerksInCategory/:id/:category_name" element={<PrivateRoute><PerksInCategory /></PrivateRoute> } />
                        <Route path="/StoreManager" element={<PrivateRoute><SearchUsersPage /></PrivateRoute> } />
                    </Routes>
                </Router>

            </div>
        );
    }
}


export default App;

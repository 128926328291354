import React, {Component} from 'react'
import MenusWrapper from '../screens/Menus';
import './style.css';
import Constants from "../lib/constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import moment from "moment";
import {getToken} from "../lib/localStorage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
const MAX_CHAR = 40;

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class Notifications extends Component{

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
        this.startloading = this.startloading.bind(this);
    }

    startloading(){
        this.setState({
            loading: true
        });
    }
    async componentWillMount() {

        fetch(Constants.API_NOTIFICATIONS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            }
        })
        .then(response => {
            this.setState({loading: false});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200) {
                this.setState({data: data.results});
            }
        });
    }

    renderItems() {
        const {data} = this.state;
        let items = [];
        if (data.length) {
            for (const value of data) {

                items.push(
                    <div className="unusdedstore" key={value.id}>
                        <table>
                            <tr>
                                <td className="td-green-dot">
                                    <span className="green-dot"></span>
                                </td>
                                <td className="tdwidthlike2"><span className="unusedbt">
                                    { ((value.Content).length > MAX_CHAR) ?
                                        (((value.Content).substring(0,MAX_CHAR-3)) + '...') :
                                        value.Content }
                                </span>
                                    <br/>
                                    <span className="redemdate">{moment(value.CreateTime).fromNow()}</span></td>
                                <td className="td-notification-arrow">
                                    <FontAwesomeIcon icon={faArrowRight} className="notification-arrow"
                                    onClick={() => alert(value.Content)}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            }
        }
        return items;
    }

    render() {

        return (
            <div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
                <div className="redeemeableblock">
                    <span id="menuwhite"><MenusWrapper /></span>
                    <h2 className="Redeemableheader">What's happening at GGV</h2>
                </div>

                { this.renderItems() }

            </div>
        );
    }
}


export default Notifications;
import Constants from "./constants";
const localStorage = window.localStorage;

export function getUserInfo() {
    return JSON.parse(localStorage.getItem(Constants.KEY_USER_INFO))
}

export function setUserInfo(data) {
    return localStorage.setItem(Constants.KEY_USER_INFO, JSON.stringify(data));
}

export function getToken() {
    return localStorage.getItem(Constants.KEY_TOKEN);
}

export function setToken(token) {
    return localStorage.setItem(Constants.KEY_TOKEN, token);
}

export function setOriginalUser(data){
    return localStorage.setItem(Constants.KEY_ORIGINAL_USER, JSON.stringify(data));
}

export function setOriginalToken(token){
    return localStorage.setItem(Constants.KEY_ORIGINAL_TOKEN, token);
}

export function getOriginalUser(){
    return JSON.parse(localStorage.getItem(Constants.KEY_ORIGINAL_USER));
}

export function getOriginalToken(){
    return localStorage.getItem(Constants.KEY_ORIGINAL_TOKEN);
}

export function removeOriginalUser(){
    return localStorage.removeItem(Constants.KEY_ORIGINAL_USER);
}

export function removeOriginalToken(){
    return localStorage.removeItem(Constants.KEY_ORIGINAL_TOKEN);
}

export function isImpersonating(){
    return localStorage.getItem(Constants.KEY_ORIGINAL_TOKEN) !== null && 
        localStorage.getItem(Constants.KEY_ORIGINAL_TOKEN)!==localStorage.getItem(Constants.KEY_TOKEN);
}

export function getRedeemedPerk() {
    return JSON.parse(localStorage.getItem(Constants.KEY_REDEEMED_PERK));
}

export function setRedeemedPerk(perk) {
    return localStorage.setItem(Constants.KEY_REDEEMED_PERK, JSON.stringify(perk));
}

export function removeRedeemedPerk() {
    return localStorage.removeItem(Constants.KEY_REDEEMED_PERK);
}
export function getBlogDetails() {
    return JSON.parse(localStorage.getItem(Constants.API_BLOGS));
}

export function setBlogDetails(blog) {
    return localStorage.setItem(Constants.API_BLOGS, JSON.stringify(blog));
}

export function removeBlogDetails() {
    return localStorage.removeItem(Constants.API_BLOGS);
}
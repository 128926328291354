import React, { Component } from 'react';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate  } from 'react-router-dom';
import logoggv from '../assets/img/ggv_logo_square.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Icon } from 'react-icons-kit';
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {twitterSquare} from 'react-icons-kit/fa/twitterSquare';
import {instagram} from 'react-icons-kit/fa/instagram';
import {youtubePlay} from 'react-icons-kit/fa/youtubePlay';
import AuthService from '../lib/auth-service';
import './style.css';



class Menus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenLeft: false
        };
        this.RedirectVapLife = this.RedirectVapLife.bind(this);
    }

    componentDidMount() {
        Modal.setAppElement(this.el);
    }
    RedirectVapLife() {
        var data = localStorage.getItem("cigarettesPerDay");

        if(data){
            this.props.navigate("/VapingLife");
        }else{
            this.props.navigate("/VapingLife1");
        }
    }

    doLogout() {
        AuthService.doLogout();
    }

    render() {
        
        return (
                <div className="col-md-5" id="menu">
                    <div ref={ref => this.el = ref}>
                    
                    <div>
                        <button  id="navbtn" onClick={ () => this.setState({ isPaneOpenLeft: true }) }>
                        <FontAwesomeIcon icon={faBars} id="navmenubar"/>
                        </button>
                    </div>
                
                    <div className="col-md-5">
                    <SlidingPane
                        className='col-md-5 menucss'
                        isOpen={ this.state.isPaneOpenLeft }
                        from='left'
                        width='80%'
                        background= '#000'
                        onRequestClose={ () => this.setState({ isPaneOpenLeft: false }) }>
                            <div className="navMenu">
                                    <Link to="/"><img src={logoggv} alt="Product"/></Link>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/Profile">My Account Info</Link></li>
                                    <li><a href="https://goodguyvapes.com/collections/all" style={{color:'rgb(137, 222, 22)'}}>Shop Now!</a></li>
                                    <li><button className="myvaplifebtn" onClick={this.RedirectVapLife}>My Vaping Life</button></li>
                                    <li><Link to="/MyRewards">Rewards</Link></li>
                                    <li><a href="https://goodguyvapes.com/apps/store-locator">Store Locator</a></li>
                                    <li><Link to="/Activity">Activity</Link></li>
                                    <li><Link to="/Settings">Settings</Link></li>
                                    <li><Link to="/Terms">Terms & Conditions</Link></li>
                                    <li><Link onClick={this.doLogout} >Logout</Link></li>
                                    
                                </ul>
                                <div className="socialsection">
                                    <a href="https://www.facebook.com/GoodGuyVapes/" target="_blank"  rel="noopener noreferrer" className="socialicon">
                                        <span className="socialicon" style={{width: 12, height: 12, color:'#fff',background:'transparent'}}> <Icon size={'10%'} icon={facebookSquare} /></span>
                                    </a>
                                    <a href="https://twitter.com/GoodGuyVapes" target="_blank"  rel="noopener noreferrer" className="socialicon">
                                        <span className="socialicon" style={{width: 12, height: 12, color:'#fff',background:'transparent'}}> <Icon size={'10%'} icon={twitterSquare} /></span>
                                    </a>
                                    <a href="https://www.instagram.com/GoodGuyVapes" target="_blank"  rel="noopener noreferrer" className="socialicon">
                                        <span className="socialicon" style={{width: 12, height: 12, color:'#fff',background:'transparent'}}> <Icon size={'10%'} icon={instagram} /></span>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCxP5vdnJSTFbOynle3qVPfg" target="_blank"  rel="noopener noreferrer" className="socialicon">
                                        <span className="socialicon" style={{width: 12, height: 12, color:'#fff',background:'transparent'}}> <Icon size={'10%'} icon={youtubePlay} /></span>
                                    </a>
                                </div>
                            </div>
                    </SlidingPane>
                    </div>
                </div>
            </div>
        );
    }
}

const MenusWrapper = () => {
    const navigate = useNavigate();
    return <Menus navigate={navigate} />;
};

export default MenusWrapper;

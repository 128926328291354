import React, {Component} from 'react'
import Menus from '../screens/Menus';
import { Link, useParams } from 'react-router-dom';
import './style.css';
import Constants from "../lib/constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
const noImg = require('../assets/img/noProductImage.jpg');

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class PerksInCategory_int extends Component{

    constructor(props) {
        super(props);
        this.state = {
            perks: [],
            loading: false
        };
        this.startloading = this.startloading.bind(this);
    }
    startloading(){
        this.setState({
           loading: true
        });
      }
    async componentWillMount() {
        // console.log(this.props.match.params);
        const {id} = this.props.params;
        // console.log("id:",id);
        fetch(Constants.API_REDEEMABLE_PRODUCTS+"?category="+id , {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            this.setState({loading: false});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200) {
                this.setState({perks: data.results});
            }
        });
    }

    renderItems() {
        const {perks} = this.state;
        let items = [];
        if (perks.length) {
            for (const value of perks) {
                const img = value.Image ? value.Image : noImg;
                items.push(
                    <div className="redeemproduct" key={value.id}>
                        <Link to={"/PerkDetails/" + value.id}>
                            <img src={img} alt="Product" />
                            <p className="redprotitle">{value.Title}</p> <span className="redprodetails">Details</span>
                            <p className="redpropint">{value.RedeemPoints} points</p>
                        </Link>
                    </div>
                )
            }
        }

        return items;
    }

    render() {

        const {category_name} = this.props.params;
        // console.log("category_name:",category_name);
        return (
            <div>
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                <div className="redeemeableblock">
                    <span id="menuwhite"><Menus /></span>
    <h2 className="Redeemableheader">{category_name} Perks</h2>
                </div>

                { this.renderItems() }

           </div>
        );
    }
}

const PerksInCategory = () => {
    const params = useParams();
    return <PerksInCategory_int params={params}/>;
};

export default PerksInCategory;
import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import './style.css';
import './Swipelistvp2.css';
import vapimg2 from '../assets/img/vapinglife2.png';

class VapingLife2_int extends Component{

    constructor(props) {
        super(props);
        this.state = { cigarettesPerDay: 4};
        this.onChange = this.onChange.bind(this);
        this._handleContinue = this._handleContinue.bind(this);
    }

    onChange(event) {
        this.setState({
            cigarettesPerDay: event.nativeEvent.target.selectedIndex
        });
    }

    _handleContinue = () => {
        localStorage.setItem('cigarettesPerDay', this.state.cigarettesPerDay);
        this.props.navigate("/VapingLife3");
    }

    renderDropdown() {
        var number = [];
        for (let i = 0; i <= 20; i++) {
            number.push(<option key={i} value={i}>{i}</option>)
        }
        return number;
    }

    render() {

        return (
            <div>
                <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                        icon={iosArrowThinLeft}/></span>
                </Link>
                <div className="vap2body">
                    <img src={vapimg2} ALT="VAP"/>
                    <p>
                        How many cigarettes<br/>do you smoke a day?
                    </p>
                </div>
            <div>
            </div>
            <div className="swipelisthide">&nbsp;</div>
                        <div className="dropdownsingle">
                            <select className="select-number" onChange={this.onChange} id="MySelect" value={this.state.cigarettesPerDay}>
                                {this.renderDropdown()}
                            </select>
                        </div>

                <div>
                    <div className="vapwelbtndiv">
                        <button className="vapbtncontinue" onClick={this._handleContinue}>Continue</button>
                        <Link to="/VapingLife1">
                        <button className="vapbtnback">Back</button>
                        </Link>
                    </div>
                </div>

            </div>
        );
    }
}

const VapingLife2 = () => {
    const navigate = useNavigate();
    return <VapingLife2_int navigate={navigate} />
}

export default VapingLife2

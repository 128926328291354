import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthService from '../lib/auth-service';

const PrivateRoute = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();
    const location = useLocation();

    return isLoggedIn ? children : <Navigate to="/Welcome" state={{ from: location }} replace />;
};

const RedirectLoggedIn = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();
    const location = useLocation();

    return !isLoggedIn ? children : <Navigate to="/" state={{ from: location }} replace />;
};

export { PrivateRoute, RedirectLoggedIn };

import React, {Component} from 'react'
import MenusWrapper from '../screens/Menus';
import { Link } from 'react-router-dom';
import './style.css';
import Constants from "../lib/constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import IconThumb from "../assets/img/icons/icon_star.svg";
import moment from "moment";
import {getToken} from "../lib/localStorage";

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;

class Activity extends Component{

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
        this.startloading = this.startloading.bind(this);
    }

    startloading(){
        this.setState({
            loading: true
        });
    }
    async componentDidMount() {

        fetch(Constants.API_ACTIVITIES + "?status=1", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            }
        })
        .then(response => {
            this.setState({loading: false});
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200 && data.results) {
                this.setState({data: data.results});
            }
        });
    }

    renderItems() {
        const {data} = this.state;
        let items = [];
        if (data.length) {
            for (const value of data) {
                let txt = value.LoyaltyTierTitle ? value.LoyaltyTierTitle : value.RedeemProductTitle;
                if (value.Type === 3) {
                    txt = "Earned Points";
                }
                const className = value.Type === 3 ? "redempts-green" : "redempts";

                items.push(
                    <div className="unusdedstore" key={value.id}>
                        <Link to="/PerkReceipt">
                            <table>
                                <tbody>
                                <tr>
                                    <td className="tdwidthlike1"><img src={IconThumb} alt="icon Thumb"/></td>
                                    <td className="tdwidthlike2"><span className="unusedbt">{txt}</span>
                                        <br/>
                                        <span className="redemdate">{moment(value.ActivityTime).fromNow()}</span></td>
                                    <td className="tdwidthlike3"><span className={className}>{(value.PointsChange > 0) ? "+" : ""}{value.PointsChange} pts</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </Link>
                    </div>
                )
            }
        }
        return items;
    }

    render() {

        return (
            <div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
                <div className="redeemeableblock">
                    <span id="menuwhite"><MenusWrapper /></span>
                    <h2 className="Redeemableheader">Activity</h2>
                </div>

                { this.renderItems() }

            </div>
        );
    }
}


export default Activity;

import React, {Component} from 'react'
import Slider from "react-slick";
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft'
import IconGift from '../assets/img/icons/icon_gift.svg';
import Constants from "../lib/constants";
import {getToken} from "../lib/localStorage";
import {fetchUserInfo} from "../lib/api";
import Modal from './Modal';
import {confirmAlert} from "react-confirm-alert";

class UpgradeTier_int extends Component{

    constructor(props) {
        super(props);
        this.state = {
            tiers: [],
            currentTier: 0,
            upgradeToTier:0,
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            loading: true,
            userInfo: null,
        };
        this._upgradeTier = this._upgradeTier.bind(this);
        this.openModalHandler = this.openModalHandler.bind(this);
    }

    async componentWillMount() {

        fetch(Constants.API_LOALTYTIERS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200) {
                this.setState({isLoading: false});
                data = this.reformatResponse(data);
                this.setState({
                    tiers: data
                })
            }
        });

        fetchUserInfo().then(userInfo => {
            this.setState({userInfo});
        });
    }

    reformatResponse = (data) => {
        return data.map( item => {
            let desc = item.Description;
            if (desc.endsWith('"')){
                desc = desc.slice(0, -1)
            }
            item.Description = JSON.parse(desc);
            return item;
        });
    }

    _handlePickup = () => {
        console.log("code "+this.state.code);
        if (
            this.state.code === null
            || this.state.code === ""
            || this.state.code.length < 4
        ) {
            alert("Please enter the 4 digit store code");
            return;
        }
        if (
            this.state.code === null
            || this.state.code == " "
            || this.state.code.length < 0
        ) {
            alert("Field should not be empty");
            return;
        }
        this.setState({loading: true, isShowing: false});
        fetch(Constants.API_UPGRADETIER, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            },
            body: JSON.stringify({
                "RedeemProductId":"",
                "LoyaltyTierId": this.state.upgradeToTier.id.toString(),
                "Type": "2",
                "Status": "1",
                "Code":this.state.code
            })
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            // TODO
            // handle invalid token
            if(statusCode === 201){
                alert('Congratulations! You are successfully upgraded.');
                this.props.navigate('MyRewards');
            } else if(statusCode === 400) {
                alert(data.error);
            } else {
                alert('Something went wrong');
            }
        })
        .catch((error) => {
            alert('Something went wrong');
        });
    }


    _handleModal = () => {
        this.setState({ modalVisible: !this.state.modalVisible})
    }
    openModalHandler = (value) => {
        this.setState({
            isShowing: true,
            upgradeToTier: value
        });
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });
    }
    renderItems() {
        const {tiers} = this.state;
        let items = [];
        if (tiers.length) {
            for (const value of tiers) {
                items.push(
                    <div key={value.id}>
                    <div className="upgslider">
                        <div className="Upgradepack">
                            <p className="upmembername">{value.Title}</p>
                            <h3 className="upmemberpoint">{value.RedeemPoints} points</h3>
                            {value.Description.Details.map((value, index) => {
                                return <span key={index}>

                                    <tr>
                                        <td><img src={IconGift} alt="icon" /></td>
                                        <td><span className="upmembervapmod">{value.Title}</span></td>
                                    </tr>
                                    <p className="uppackdetails">{value.Note}</p>

                                        </span>
                            })}
                        </div>
                        </div>
                        
                        <p className="upmembdetails">{value.Description.Description}</p>
                        <div className="upgradebutton">
                            <input type="button" value="Upgrade Now" className="upgradebtn" onClick={() => this._upgradeTier(value)} />
                        </div>
                    
                    </div>
                )
            }
        }

        return items;
    }

    upgradeCore=(value)=>{
        fetch(Constants.API_UPGRADETIER, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            },
            body: JSON.stringify({
                "RedeemProductId":"",
                "LoyaltyTierId": value.id.toString(),
                "Type": "2",
                "Status": "1"
            })
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            // TODO
            // handle invalid token
            if(statusCode === 201){
                alert('Congratulations! You are successfully upgraded.');
                this.props.navigate('MyRewards');
            } else if(statusCode === 400) {
                alert(data.error);
            } else {
                alert('Something went wrong');
            }
        })
        .catch((error) => {
            alert('Something went wrong');
        });
    }
    
    _upgradeTier(value) {

        confirmAlert({
            title: 'Message:',
            message: 'VIP Tier Redemption can only be upgraded in a Good Guy Vapes location by a store employee. Please visit the Good Guy Vapes location that you frequent and allow the associate to assist you in this process. (Can not be redeemed in NJ)',
            buttons: [
                {
                    label: 'UPGRADE',
                    onClick: () => this.openModalHandler(value)
                },
                {
                    label: 'CANCEL'
                }
            ]
        });

    }

    render() {
        const {userInfo} = this.state;
        const userTier = userInfo ? userInfo.CurrentTier : 0;
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: userTier,
            beforeChange: (current, next) => {
                this.setState({ currentTier: current })
            },
            afterChange: current => {
                this.setState({ currentTier: current })
            }
        };
        return (
                <div className="UpgradeTier">
                    <div>
                    <span className="pagebackmargin">
                        <Link to="/MyRewards">
                            <span style={{width: 12, height: 12, color:'#fff'}}> <Icon size={'12%'} icon={iosArrowThinLeft} /></span>
                        </Link>
                    </span>
                        <h2 className="upgradememtitle">Upgrade your membership</h2>
                        {userInfo &&
                            <Slider {...settings}>
                                { this.renderItems() }
                            </Slider>
                        }
                        
                    </div>
                    <Modal
                        className="modal"
                        show={this.state.isShowing}
                        close={this.closeModalHandler}>
                        <p className="Storecodetxt">Store code</p>
                        <input type="text" className="storeinput" onChange={ event => this.setState({code: event.target.value}) } required/>
                        <button className="storebtn" onClick={() =>this._handlePickup()}>Submit</button>

                    </Modal>
                </div>
            
        );
    }
}


const UpgradeTier = () => {
    const navigate = useNavigate();
    return <UpgradeTier_int navigate={navigate}/>;
};


export default UpgradeTier;
import React, {Component} from 'react'
import './style.css';
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import moment from 'moment';

import IconCigar from '../assets/img/icons/icon_cigar.svg';
import IconMoney from '../assets/img/icons/icon_money.svg';


class VapingLife_int extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cigarettesPerDay: 0,
            cigarettesInPack: 0,
            yearsOfSmoking: 0,
            priceOfCigarette: 0,
            quitDate: moment().format("YYYY-MM-DD"),
            avoidedCigarettes: 0,
            savedMoney: 0,
            timeWon: 0,
            bloodPressure: 0,
            savedDay: 0
        }
        this.resetStorage = this.resetStorage.bind(this);
    }

    async componentDidMount() {
        //this.Storage.store("flag", JSON.stringify(true));
        const n = localStorage.getItem("cigarettesPerDay");
        const m = localStorage.getItem("cigarettesInPack");
        const y = localStorage.getItem("yearsOfSmoking");
        const p = localStorage.getItem("priceOfCigarette");
        const quitDate = localStorage.getItem("quitDate");
        const quitDay = moment(quitDate, "YYYY-MM-DD HH:mm").startOf('day');
        const today = moment().startOf('day');
        const diffDay = today.diff(quitDay, 'day') < 0 ? 0 : today.diff(quitDay, 'day');
        const avoidedCigarettes = (n * diffDay).toFixed();
        const savedMoney = n * p * diffDay / m;

        const startYear = moment.utc(quitDate.slice(1, 11)).local().startOf('year');
        const thisYear = moment().startOf('year');
        const diffYear1 = thisYear.diff(startYear, 'year') < 0 ? 0 : thisYear.diff(startYear, 'year');

        const birthDay = moment.utc(this.props.userInfo).local().startOf('year');
        const diffYear2 = thisYear.diff(birthDay, 'year') < 0 ? 0 : thisYear.diff(birthDay, 'year');

        const diffYear = diffYear2 - diffYear1;
        const timeWon = diffYear <35
            ? 10
            : diffYear < 45
                ? 9
                : diffYear < 55
                    ? 6
                    : 3

        const todayTime = moment().format();
        const ms = moment(todayTime,"YYYY-MM-DD HH:mm:ss").diff(moment(quitDate,"YYYY-MM-DD HH:mm"));
        const duration = moment.duration(ms);
        const hourGap = Math.abs(duration.hours()) + Math.abs(duration.days()) * 24 + Math.abs(duration.years()) * 365 * 24;
        const minuteGap = Math.abs(duration.minutes()) + hourGap * 60;

        const bloodPressure = minuteGap > 20 ? 100 : (minuteGap.to * 5).toFixed(0);

        this.setState({
            savedMoney: savedMoney.toFixed(0) | 0,
            avoidedCigarettes,
            timeWon,
            bloodPressure,
            savedDay: diffDay
        })
    }

    resetStorage = () => {
        localStorage.removeItem("cigarettesPerDay");
        localStorage.removeItem("cigarettesInPack");
        localStorage.removeItem("yearsOfSmoking");
        localStorage.removeItem("priceOfCigarette");
        localStorage.removeItem("quitDate");
        localStorage.removeItem("daysDate");
        localStorage.removeItem("Date");
        localStorage.removeItem("priceOfCigaretteNumber");
        localStorage.removeItem("VapLife5From");
        localStorage.removeItem("flag");
        this.props.navigate("/VapingLife1");
    }

    render() {
        const state = this.state;
        return (
            <div>
                    <div className="Vapinglifebg">
                    <Link to="/Welcome" className="Vapinglifeback">
                    <span className="backiconleft" style={{width: 12, height: 12, color: '#fff'}}> <Icon size={'12%'}
                                                                                   icon={iosArrowThinLeft}/></span>
                    </Link>
                        <div className="vapingheadtext">
                            <p>
                                It has been {this.state.savedDay} days since <br/>
                                you've began your <br/>
                                Vaping Journey
                            </p>
                        </div>
                    <div className="vapheadbottom">
                        <div className="vaptable">
                        <table className="vaptableele">
                            <tr>
                                   <td className="vaptd"> <img src={IconCigar} alt="IconCigar" /></td>
                                   <td className="vaptd1"> Cigarettes avoided</td>
                                   <td className="vaptd2">{state.avoidedCigarettes}</td>
                                   
                               </tr>
                               <tr>
                                   <td className="vaptd"> <img src={IconMoney} alt="IconMoney" /></td>
                                   <td className="vaptd1"> Money saved</td>
                                   <td className="vaptd2">${state.savedMoney}</td>
                               </tr>
                            </table>
                        </div>
                    </div>
                    </div>
                        
                    <div className="vaphealthmeter">
                        <p className="healthtxt">Health</p>
                        <div className="healthbody">
                            <tr>
                                <td className="healthmeterper"><img src={require('../assets/img/icons/icon_meter.png')} /><br/><p>{this.state.bloodPressure}%</p></td>
                                <td className="healthmeterpertxt">"Your blood pressure, pulse rate and the temperature of your heat is normal"</td>
                            </tr>
                        </div>
                        </div>

                <div className="vapwelbtndiv">
                    <Link to="/HealthDetails">
                    <button className="vapbtncontinue">Full Health Details</button>
                    </Link>
                    <button onClick={this.resetStorage} className="vapbtnback">Reset Counter</button>

                </div>
            </div>
        );
    }
}

const VapingLife = (props) => {
    const navigate = useNavigate();
    return <VapingLife_int {...props} navigate={navigate} />
}


export default VapingLife;

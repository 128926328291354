import React, {Component} from 'react'
import './style.css';
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`

    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;
class Terms extends Component{
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
        this.hideLoading = this.hideLoading.bind(this);
      }
      hideLoading = () => {
        this.setState({
          loading: false
        });
      };

    render(){
        return(
            <div>
                {this.state.loading ? (
                <ClipLoader
                css={override}
                
                size={50}
                color={'#123abc'}
                loading={this.state.loading}
                />
        ) : null}
                <Link to="/" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color:'#000'}}> <Icon size={'12%'} icon={iosArrowThinLeft} /></span>
                </Link>
            <div className="Terms">
                    <iframe src="https://goodguyvapes.com/pages/terms-of-service" onLoad={this.hideLoading} title="terms" style={{border:'none', width:'100%',height:'100%'}}></iframe>
            </div>
            </div>
            
                  
        );
    }
}

export default Terms;
import React, {Component} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft'
import './style.css';
import {Tabs, Tab} from 'react-tab-view'
import noImg from '../assets/img/noProductImage.jpg';
import Constants from "../lib/constants";
import {getToken, setRedeemedPerk} from "../lib/localStorage";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:50%;
    left: 40%;
    z-index: 3;
`;
class PerkDetails extends Component {

    constructor(props){
        super(props);
        this.state = {
            perk: {},
            loading: false
        };
        this.redeemProduct = this.redeemProduct.bind(this);
    }

    componentWillMount () {
        const {id} = this.props.params
        fetch(Constants.API_REDEEMABLE_PRODUCTS + id + "/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            const status = response.status;
            return Promise.all([status, response.json()]);
        })
        .then(([status, data]) => {
            if (status === 200) {
                this.setState({perk: data});
            }
        });
    }

    redeemProduct = () => {
        this.setState({loading: true});
        fetch(Constants.API_REDEEM, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            },
            body: JSON.stringify({
                "RedeemProductId": this.state.perk.id.toString(),
                "LoyaltyTierId": "",
                "Status": "0",
                "Type": "1"
            })
        })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {

            //TODO
            // handle invalid or expired token(403)
            if(statusCode === 201){
                setRedeemedPerk(data);
                this.props.navigate("/PerkReceipt");
            } else if(statusCode === 400) {
		this.setState({loading: false});
                alert(data.error);
            } else if(statusCode === 403) {
                this.setState({loading: false});
                alert('Your account is not active yet. Please confirm your email & try again.');
            }  else {
                alert('Something went wrong');
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    render() {
        const headers = ['Overview', 'How to use', 'T&C'];
        const {perk} = this.state;
        const img = perk.Image ? perk.Image: noImg;

        return (
            <div>
                <span className="pagebackmargin">
                <Link to="/PerkCategories">
                    <span style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                icon={iosArrowThinLeft}/></span>
                </Link>
		</span>
                <div className="productimg">
                    <img src={img} alt="product"/>
                </div>
                <div className="parkdetailstitle">
                    <h3>{perk.Title}</h3><span className="newtext">NEW!</span>
                    <br/>
                    <br/>
                    <div className="parkdetailspoint">{perk.RedeemPoints} Points</div>

                </div>
                <br/><br/>
                <div>
                    <Tabs headers={headers}>
                        <Tab>
                            <div>
                                <p>{perk.Description}</p>
                            </div>
                        </Tab>
                        <Tab>
                            <p>Once redeemed, visit your nearest Good Guy Vapes location and show this page to the sales
                                associate.
                                They will be able to assist you and properly apply your perk or discount to your next
                                purchase.
                            </p>
                        </Tab>
                        <Tab>
                            <p>Limited to one perk redemption per transaction per day. Cannot be combined with any
                                other discounts, offers,
                                or current promotional pricing. Perks cannot be redeemed for cash value. This perk is
                                single use only, and may
                                not be resistributed to other users.
                            </p>
                        </Tab>
                    </Tabs>
                </div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                <div className="redeembtndiv">
                    <input className="Redetbtn" type="button" value="Redeem" onClick={this.redeemProduct}/>
                </div>

            </div>

        );
    }
}


const PerkDetailsWrapper = () => {
    const params = useParams();
    const navigate = useNavigate();
    return <PerkDetails params={params} navigate={navigate}/>;
};

export default  PerkDetailsWrapper;

import React from 'react';

import './style.css';

const modal = (props) => {
    return (
        <div>
            <div className="modal-wrapper"
                style={{
                    opacity: props.show ? '1' : '0'
                }}>
                <div className="modal-header">
                    <div className="close-modal-btn" onClick={props.close}>×</div>
                </div>
                <div className="modal-body">
                    <p>
                        {props.children}
                    </p>
                </div>

            </div>
        </div>
    )
}

export default modal;

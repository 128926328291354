import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

//override default alert function
window.alert = function(msg) {
    confirmAlert({
        title: 'Message:',
        message: msg,
        buttons: [
            {
                label: 'Okay',
                onClick: () => {}
            }
        ]
    });
};
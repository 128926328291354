import React, {Component} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import moment from "moment";
import './style.css';
import MenusWrapper from '../screens/Menus';
import IconShop from '../assets/img/icons/icon_shop.svg';
import IconThumb from '../assets/img/icons/icon_ggvthumb.svg';
import Problank from '../assets/img/blank-profile-image.png';
import {fetchUserInfo} from "../lib/api";
import Constants from "../lib/constants";
import {getToken, setRedeemedPerk} from "../lib/localStorage";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top:80%;
    left: 40%;
    z-index: 3;
`;

const HIGHEST_TIER = 3;

class MyRewards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            token: null,
            unusedPerks: [],
            usedPerks: [],
            loading: false
        };
        this._renderUnusedPerkList = this._renderUnusedPerkList.bind(this);
        this._renderUsedPerkList = this._renderUsedPerkList.bind(this);
        this._openPerkReceipt = this._openPerkReceipt.bind(this);
        this._openUnusedPerkReceipt = this._openUnusedPerkReceipt.bind(this);
        this.startloading = this.startloading.bind(this);
        this.openUpgradeTierPage = this.openUpgradeTierPage.bind(this);
        this.openPerkCategories = this.openPerkCategories.bind(this);
    }
    startloading(){
        this.setState({
           loading: true
        });
      }
    async componentDidMount() {
        fetchUserInfo().then(userInfo => {
            this.setState({userInfo});
        });

        this._fetchUnusedPerks();
        this._fetchUsedPerks();
    }

    openUpgradeTierPage() {
        const {userInfo} = this.state;
        // const navigate = this.props.navigate;
        if (userInfo.CurrentTier === HIGHEST_TIER){
            alert('You are already at the highest tier!');
        } else {
            this.props.navigate('/UpgradeTier');
        }
    }

    openPerkCategories(){
        const {userInfo} = this.state;
        console.log("state aaa: "+userInfo.InternalState);
        if (userInfo.InternalState === "XX")
        {
            alert('You cannot redeem perks yet. Please bring this to a Good Guy Vapes store.');
        }else{
            this.props.navigate('/PerkCategories');
        }
    }

    _fetchUnusedPerks = () => {
        fetch(Constants.API_ACTIVITIES + "?status=0", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + getToken()
                }
            })
            .then(response => {
                this.setState({loading: false});
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(async ([statusCode, data]) => {
                // TODO show error alert if code != 200
                if(statusCode === 200){
                    this.setState({unusedPerks: data.results});
                }
            })
            .catch((error) => {
                console.error(error);
                // TODO show error alert
            });
    }

    _fetchUsedPerks = () => {
        fetch(Constants.API_ACTIVITIES + "?status=1", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + getToken()
            }
        })
        .then(response => {
            this.setState({loading: false});
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(async ([statusCode, data]) => {
            // TODO show error alert if code != 200
            if(statusCode === 200 && data.results.length){
                const filtered = data.results.filter( i => i.Type !== 3);
                this.setState({ usedPerks: filtered});
            }
        })
        .catch((error) => {
            console.error(error);
            // TODO show error alert
        });
    }

    _openPerkReceipt = (item) => {
        setRedeemedPerk(item);
        this.props.navigate('/PerkReceipt');
    }

    _openUnusedPerkReceipt = (item) => {
        setRedeemedPerk(item);
        this.props.navigate('/UnusedPerkReceipt');
    }

    _renderUnusedPerkList() {
        const {unusedPerks} = this.state;
        let items = [];
        if (unusedPerks.length) {
            for (const value of unusedPerks) {
                items.push(
                    <div className="unusdedstore" key={value.id} onClick={() => this._openPerkReceipt(value)}>
                        <div className="perkstext">
    <table>
        <tbody>
                            <tr>
                                <td className="shopicon"><img src={IconShop} alt="shop icon"/></td>
                                <td><span className="unusednt">You still need to redeem your <span className="unusedbt">{value.RedeemProductTitle} </span>perk in store!</span></td>
                            </tr>
        </tbody>
    </table>
                        </div>
                    </div>
                )
            }
        }
        return items;
    }

    _renderUsedPerkList() {
        const {usedPerks} = this.state;
        let items = [];
        if (usedPerks.length) {
            for (const value of usedPerks) {
                const title = value.LoyaltyTierTitle ? value.LoyaltyTierTitle : value.RedeemProductTitle;
                items.push(
                    <div className="unusdedstore" key={value.id} onClick={() => this._openUnusedPerkReceipt(value)}>
                        <Link to="/PerkReceipt">
                        <table>
                        <tbody>
                        <tr>
                            <td className="tdwidthlike1"><img src={IconThumb} alt="icon Thumb"/></td>
                            <td className="tdwidthlike2"><span className="unusedbt">{title}</span>
                            <br/>
                            <span className="redemdate">{moment(value.ActivityTime).format('MM/DD/YYYY')}</span></td>
                            <td className="tdwidthlike3"><span className="redempts">{value.PointsChange} pts</span></td>
                        </tr>
                        </tbody>
                        </table>
                        </Link>
                    </div>
                )
            }
        }
        return items;
    }

    render() {

        const {userInfo} = this.state;
        const photo = userInfo.ProfilePhoto ? userInfo.ProfilePhoto : Problank;
        return (
            <div>
                <ClipLoader
                    css={override}
                    
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                    />
                <MenusWrapper/>
                <div id="profile">
                    <h3>{userInfo.first_name}'s reward<br/> {userInfo.Points} points</h3>
                    <div className="profile-avatar" style={{backgroundImage: `url(${photo})`}}>
                    </div>
                    <br/>
                    <span className="accname">{userInfo.CurrentTierTitle}</span>
                    <br/>
                    <input className="Upgradebtn" type="button" value="Upgrade" onClick={this.openUpgradeTierPage} />
                    <input className="Redeembtn" type="button" value="Redeem" onClick={this.openPerkCategories}/>
                    
                </div>
                <div className="unusedperksection">
                    <h4 className="unusedpertext">Unused Perks</h4>
                    { this.state.unusedPerks.length ? this._renderUnusedPerkList() : <span className="myrewardsunavailable">You have no unused perks</span> }
                </div>

                <div className="unusedperksection">
                    <h4 className="unusedpertext">Redemption Activity</h4>
                    { this.state.usedPerks.length ? this._renderUsedPerkList() :  <span className="myrewardsunavailable">You have no used perks</span> }
                </div>

            </div>

        );
    }
}

const MyRewardsWrapper = () => {
    const navigate = useNavigate();
    return <MyRewards navigate={navigate}/>;
};

export default MyRewardsWrapper;


import {getUserInfo} from "./localStorage";
import Constants from "./constants";

export function fetchUserInfo() {

    return new Promise(async (resolve, reject) => {
        const userInfo = getUserInfo();

        if (userInfo === null){
            reject('User Info not found in local storage');
        }
        // console.log({userInfo});

        let response = await fetch(Constants.API_USERS + userInfo.user_id +"/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if(response.status === 200){
            resolve(response.json());
        } else {
            reject('Something went wrong');
        }
    })
}
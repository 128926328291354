import React, {Component} from 'react'
import './style.css';
import {Link, useNavigate} from 'react-router-dom';
import {Icon} from 'react-icons-kit'
import {iosArrowThinLeft} from 'react-icons-kit/ionicons/iosArrowThinLeft';
import bgimg from '../assets/img/vapinglife4.png';
import Storage from "../lib/storage";
import SwipeList from "./SwipeListvl4";

class VapingLife4_int extends Component{

    constructor(props) {
        super(props);
        this.state = { yearsOfSmoking: 4 }
        this.onChange = this.onChange.bind(this);
        this._handleContinue = this._handleContinue.bind(this);
    }

    onChange(event) {
        this.setState({
            yearsOfSmoking: event.nativeEvent.target.selectedIndex
        });
    }

    _handleContinue = () => {
        localStorage.setItem('yearsOfSmoking', this.state.yearsOfSmoking);
        this.props.navigate("/VapingLife5");
    }

    renderDropdown() {
        var number = [];
        for (let i = 0; i <= 20; i++) {
            number.push(<option key={i} value={i}>{i}</option>)
        }
        return number;
    }


    render() {
        return (
            <div>
                <Link to="/Welcome" className="welcreate">
                        <span className="backiconleft" style={{width: 12, height: 12, color: '#000'}}> <Icon size={'12%'}
                                                                                                        icon={iosArrowThinLeft}/></span>
                </Link>
                <div className="vap4div">
                    <img src={bgimg} alt="vap4"/>
                    <p>
                        How many years have <br/>
                        you been smoking?
                    </p>
                </div>
                <div className="swipelisthide">&nbsp;</div>
                        <div className="dropdownsingle">
                            <select className="select-number" onChange={this.onChange} id="MySelect" value={this.state.yearsOfSmoking}>
                                {this.renderDropdown()}
                            </select>
                        </div>
                <div>
                    <div className="vapwelbtndiv">
                        <button className="vapbtncontinue" onClick={this._handleContinue}>Continue</button>
                        <Link to="/VapingLife3">
                        <button className="vapbtnback">Back</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const VapingLife4 = () => {
    const navigate = useNavigate();
    return <VapingLife4_int navigate={navigate}/>
}

export default VapingLife4
